import CheckoutForm from "../Components/CheckoutForm/CheckoutForm";
import PageHeader from "../Components/PageHeader/PageHeader";

const CheckOut = () => {
  return (
    <div>
      <PageHeader
        title={"Package > Checkout"}
        subTitle={"Checkout"}
        from="course"
      />
      <CheckoutForm />
    </div>
  );
};
export default CheckOut;
