import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resetLessonQiuzQuestionAnswer } from "../../services/quiz";
import { useApp } from "../../context/AppProvider";

export function useResetQuizQuestion() {
  const { token } = useApp();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ userId, lessonId, questionId , level}) =>
      resetLessonQiuzQuestionAnswer(token, userId, lessonId, questionId , level),
    onSuccess: () => {
      queryClient.invalidateQueries(["examAnswer"]);
    },
  });

  return { resetQuestion: mutate, isLoading: isPending };
}
