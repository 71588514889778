import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editProfilePhoto } from "../../services/Auth";
import toast from "react-hot-toast";

export function useEditProfilePhoto() {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ userId, editImage, token }) =>
      editProfilePhoto(userId, editImage, token),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      toast.success("Edit profile photo successfully");
    },
    onError: (error) => toast.error(error.message),
  });

  return { editProfilePhoto: mutate, isLoading: isPending };
}
