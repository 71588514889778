import { useMutation } from "@tanstack/react-query";
import { login } from "../../services/Auth";
import toast from "react-hot-toast";
import { useApp } from "../../context/AppProvider";

export function useSignin() {
  const { setToken } = useApp();
  const { mutate, isPending } = useMutation({
    mutationFn: (user) => login(user),
    onSuccess: (data) => {
      setToken(data.token)
      toast.success("Welcome, Login succesfully");
    },
    onError: (error) => toast.error(error.message),
  });

  return { signin: mutate, isLoading: isPending };
}
