import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import { getPackage } from "../../services/packages";

export function useGetPackage(id) {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["package", id],
    queryFn: () => getPackage(token, id),
  });

  return { package: data, isLoading };
}
