import React, { useState } from "react";
import Input from "../Input/Input";
import "./AuthForm.css";
import logo from "../../assets/images/logo.jpg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { BeatLoader } from "react-spinners";
import { useResetPassword } from "../../hooks/Auth/useResetPassword";
import { useNavigate, useParams } from "react-router-dom";

function ResetPasswordForm() {
  const [showPassword, setShowPassword] = useState(false);
  const schema = yup.object({
    otp: yup
      .string()
      .required("Please enter reset password code")
      .min(5, "reset password code must be 5 numbers")
      .max(5, "reset password code must be 5 numbers"),
    password: yup
      .string()
      .required("Please enter your password")
      .min(8, "Password Must be At Least 8 Characters"),
    confirmPassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Passwords Must Match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { resetPassword, isLoading } = useResetPassword();

  const { email } = useParams();
  const navigate = useNavigate();

  function onSubmit(data) {
    resetPassword(
      {
        userEmail: email,
        otp: data.otp,
        newPassword: data.password,
      },
      { onSuccess: () => {} }
    );
  }

  return (
    <div className="AuthForm_container py-5">
      <div className="form_container py-5">
        <div className="logo_container">
          <img src={logo} alt="logo" />
          <p className="title">Reset Password</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input_container">
            <label htmlFor="otp">Reset Code</label>
            <Input
              type="text"
              palceholder="Enter your otp"
              name="otp"
              id="otp"
              register={register}
              error={errors.otp ? errors.otp : null}
            />
            {errors.otp && <p className="error">{errors.otp.message}</p>}
          </div>
          <div
            className={`input_container ${
              errors.password ? "error_container" : ""
            }`}
          >
            <label htmlFor="password">New Password</label>
            <Input
              type="password"
              palceholder="Enter your password"
              name="password"
              id="password"
              register={register}
              error={errors.password ? errors.password : null}
              show={showPassword}
              setShow={setShowPassword}
            />
            {errors.password && (
              <p className="error">{errors.password.message}</p>
            )}
          </div>

          <div
            className={`input_container ${
              errors.confirmPassword ? "error_container" : ""
            }`}
          >
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Input
              type="password"
              palceholder="Confirm your password"
              name="confirmPassword"
              id="confirmPassword"
              register={register}
              error={errors.confirmPassword ? errors.confirmPassword : null}
              show={showPassword}
              setShow={setShowPassword}
            />
            {errors.confirmPassword && (
              <p className="error">{errors.confirmPassword.message}</p>
            )}
          </div>
          <button type="submit" className="submit_button" disabled={isLoading}>
            {isLoading ? (
              <BeatLoader color="white" size={10} />
            ) : (
              "Reset Password "
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
