import { useGetUser } from "../../hooks/Auth/useGetUser";
import { useGetAttendedExams } from "../../hooks/profile/useGetAttendedExams";
import Loader from "../Loader/Loader";
import styles from "./QuizesTable.module.css";
import QuizesTableRow from "./QuizesTableRow";

function QuizesTable() {
  const { user } = useGetUser();

  const { attendedExams, isLoading } = useGetAttendedExams(user.id, "quiz");

  if (isLoading) return <Loader type="mini" />;

  if (attendedExams.length === 0) {
    return (
      <div className={`${styles.empty_table}`}>
        Not Exist Attended Lesson Quiz
      </div>
    );
  }

  return (
    <table className={`${styles.quiz_table}`}>
      <tbody>
        <tr>
          <td>Course Name</td>
          <td>Chapter Name</td>
          <td>Lesson Name</td>
          {/* <td>Date</td> */}
          <td>Status</td>
        </tr>
        {attendedExams.map((lesson) => (
          <QuizesTableRow lesson={lesson} key={lesson.lessonId} />
        ))}
      </tbody>
    </table>
  );
}
export default QuizesTable;
