import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import { getUser } from "../../services/Auth";

export function useGetUser() {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["user", token],
    queryFn: () => getUser(token),
  });

  return { user: data, isLoading };
}
