import React from "react";
import CourseContainer from "../Components/CourseContainer/CourseContainer";

function Course() {
  return (
    <div>
      <CourseContainer />
    </div>
  );
}

export default Course;
