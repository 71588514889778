import React, { useState } from "react";
import Input from "../Input/Input";
import "./AuthForm.css";
import logo from "../../assets/images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSignin } from "../../hooks/Auth/useSignin";
import { BeatLoader } from "react-spinners";

function LoginForm() {
  const schema = yup.object({
    email: yup
      .string()
      .required("Please enter your email")
      .email("Invalid Email Format"),
    password: yup
      .string()
      .required("Please enter your password")
      .min(8, "Password Must be At Least 8 Characters"),
  });

  const { signin, isLoading } = useSignin();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function onSubmit(data) {
    signin(data, {
      onSuccess: () => {
        navigate("/");
      },
    });
  }

  return (
    <div className="AuthForm_container py-5">
      <div className="form_container py-5">
        <div className="logo_container">
          <img src={logo} alt="logo" />
          <p className="title">Login</p>
          <p>
            Welcome back, entry your details to get access to Mathapes
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input_container">
            <label htmlFor="email">Email</label>
            <Input
              type="text"
              palceholder="Enter your email"
              name="email"
              id="email"
              register={register}
              error={errors.email ? errors.email : null}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>

          <div className="input_container">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              palceholder="Enter your password"
              name="password"
              id="password"
              register={register}
              error={errors.password ? errors.password : null}
              show={showPassword}
              setShow={setShowPassword}
            />
            {errors.password && (
              <p className="error">{errors.password.message}</p>
            )}
            <Link to="/forgetPassword">Forget Password ?</Link>
          </div>

          <button type="submit" className="submit_button" disabled={isLoading}>
            {isLoading ? <BeatLoader color="white" size={10} /> : "Login"}
          </button>

          <p className="login_option">
            You don't have an account? <Link to={"/register"}>Register</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
