import { useQuery } from "@tanstack/react-query";
import {
  getAttendedFinalExams,
  getAttendedLessonQuiz,
} from "../../services/profile";
import { useApp } from "../../context/AppProvider";

export function useGetAttendedExams(userId, type) {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["attendedLessonQuiz", userId , type],
    queryFn: () =>
      type === "quiz"
        ? getAttendedLessonQuiz(token, userId)
        : getAttendedFinalExams(token, userId),
  });

  return { attendedExams: data, isLoading };
}
