import React from 'react'
import FAQContainer from '../Components/FAQContainer/FAQContainer'

function FAQ() {
  return (
    <div>
        <FAQContainer />
    </div>
  )
}

export default FAQ