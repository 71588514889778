import { useGetUser } from "../hooks/Auth/useGetUser";
import Loader from "../Components/Loader/Loader";
import ProfileContainer from "../Components/ProfileContainer/ProfileContainer";

function Profile() {
  const { isLoading } = useGetUser();
  if (isLoading) return <Loader />;
  return (
    <div>
      <ProfileContainer />
    </div>
  );
}
export default Profile;
