import React from "react";
import ContactusContainer from "../Components/ContactusContainer/ContactusContainer";

function Contactus() {
  return (
    <div>
      <ContactusContainer />
    </div>
  );
}

export default Contactus;
