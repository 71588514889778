import { useLocation } from "react-router-dom";
import PageHeader from "../Components/PageHeader/PageHeader";
import QuizContainer from "../Components/QuizContainer/QuizContainer";
import FreeQuizContainer from "../Components/FreeQuizContainer/FreeQuizContainer";

function Quiz() {
  const location = useLocation();
  const currentPage = location.pathname;
  return (
    <div>
      <PageHeader
        title="Courses > Course > Quiz"
        subTitle="Quiz"
        from="course"
      />
      {currentPage.includes("/courses/course/lesson/quizFreeTrier") ? (
        <FreeQuizContainer />
      ) : (
        <QuizContainer />
      )}
    </div>
  );
}
export default Quiz;
