import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import ProfileTap from "../ProfileTap/ProfileTap";
import { useApp } from "../../context/AppProvider";
function Navbar() {
  const location = useLocation();
  const { token } = useApp();
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <Link className="navbar-brand" to="/">
        <div className="d-flex align-items-center">
          <img
            alt="logo"
            src={logo}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
          <p className="mb-0 mx-2" style={{ fontWeight: "600" }}>
          Mathapes
          </p>
        </div>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mx-auto">
          <li
            className={
              location.pathname.slice(1).length === 0
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li
            className={
              location.pathname.slice(1) === "courses"
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/courses">
              Courses
            </Link>
          </li>
          <li
            className={
              location.pathname.slice(1) === "aboutus"
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/aboutus">
              About us
            </Link>
          </li>
          <li
            className={
              location.pathname.slice(1) === "terms"
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/terms">
              Terms
            </Link>
            
          </li>
          <li
            className={
              location.pathname.slice(1) === "contactus"
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/faq">
             FAQ
            </Link>
          </li>
                <li
            className={
              location.pathname.slice(1) === "contactus"
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/contactus">
              Contact us
            </Link>
          </li>
        </ul>
        <ul className="d-flex align-items-center auth_container">
          {token !== null ? (
            <ProfileTap />
          ) : (
            <>
              <li className="px-3 nav-link py-1 mx-3 login-link">
                <Link to={"/login"}>
                  <p className="mb-0 login-text">Login</p>
                </Link>
              </li>
              <li className="px-3 nav-link py-1 mx-3 register-link">
                <Link to={"/register"}>
                  <p className="mb-0 register-text">Register</p>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
