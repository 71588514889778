export function dateFormat(dateString) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Extract the date components
  const year = date.getUTCFullYear();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getUTCMonth()]; // Get the month name
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Format the date
  const formattedDate = `${day}, ${month} ${year}`;

  return formattedDate;
}

export function getDateAfterMonths(n) {
  const currentDate = new Date(); // Get the current date
  const futureDate = new Date(currentDate); // Create a copy of the current date

  futureDate.setMonth(futureDate.getMonth() + n); // Add n months to the current month

  return dateFormat(futureDate); // Return the calculated future date
}

export function convertToBase64(e, callBack) {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callBack(reader.result);
    };
    reader.readAsDataURL(file);
  }
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
