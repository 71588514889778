import React, { useEffect, useState } from "react";
import Input from "../Input/Input";
import "./AuthForm.css";
import logo from "../../assets/images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSignup } from "../../hooks/Auth/useSignup";
import { BeatLoader } from "react-spinners";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat } from "../../utils/helper";

function RegisterForm() {
  const [startDate, setStartDate] = useState(new Date());

  const schema = yup.object({
    firstName: yup
      .string()
      .required("Please enter your username")
      .min(3, "Username Should Be At Least 3 Characters")
      .max(15, "Username Should Be At Most 15 Characters"),
    lastName: yup
      .string()
      .required("Please enter your username")
      .min(3, "Username Should Be At Least 3 Characters")
      .max(15, "Username Should Be At Most 15 Characters"),
    email: yup
      .string()
      .required("Please enter your email")
      .email("Invalid Email Format"),
    password: yup
      .string()
      .required("Please enter your password")
      .min(8, "Password Must be At Least 8 Characters"),
    confirmPassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Passwords Must Match"),
    city: yup.string().required("Please enter your city"),
    country: yup.string().required("Please enter your country"),
    school: yup.string().required("Please enter your school"),
    gender: yup.string().required("Please enter your gender"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
  } = useForm({ resolver: yupResolver(schema) });
  const phoneValue = watch("phone");
  const parentPhoneValue = watch("parentPhone");

  const { signup, isLoading } = useSignup();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  function onSubmit(data) {
    if (!data.phone) {
      setError("phone", {
        type: "manual",
        message: "Phone number is required",
      });
      if (!data.parentPhone) {
        setError("parentPhone", {
          type: "manual",
          message: "Parent phone number is required",
        });
      }
      return;
    }

    if (!data.parentPhone) {
      setError("parentPhone", {
        type: "manual",
        message: "Parent phone number is required",
      });
      return;
    }

    const newUser = {
      first_name: data.firstName?.trim(),
      last_name: data?.lastName?.trim(),
      email: data.email?.trim(),
      password: data?.password?.trim(),
      phone: data.phone?.trim(),
      parentPhone: data.parentPhone?.trim(),
      name: `${data.firstName?.trim()} ${data.lastName?.trim()}`,
      school: data.school?.trim(),
      city: data.city?.trim(),
      country: data.country?.trim(),
      birthday: dateFormat(startDate),
      gender: data.gender,
    };

    signup(newUser, {
      onSuccess: () => {
        navigate("/login");
      },
    });
  }

  useEffect(
    function () {
      register("phone");
      register("parentPhone");
    },
    [register]
  );

  return (
    <div className="AuthForm_container py-5">
      <div className="form_container py-5">
        <div className="logo_container">
          <img src={logo} alt="logo" />
          <p className="title">Register</p>
          <p>
            Create an account get access to Mathapes
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="nameInput">
            <div
              className={`input_container ${
                errors.name ? "error_container" : ""
              }`}
            >
              <label htmlFor="firstName">First Name</label>
              <Input
                type="text"
                palceholder="Enter your first name"
                name="firstName"
                id="firstName"
                register={register}
                error={errors.firstName ? errors.firstName : null}
              />
              {errors.firstName && (
                <p className="error">{errors.firstName.message}</p>
              )}
            </div>
            <div
              className={`input_container ${
                errors.name ? "error_container" : ""
              }`}
            >
              <label htmlFor="lastName">Last Name</label>
              <Input
                type="text"
                palceholder="Enter your last name"
                name="lastName"
                id="lastName"
                register={register}
                error={errors.lastName ? errors.lastName : null}
              />
              {errors.lastName && (
                <p className="error">{errors.lastName.message}</p>
              )}
            </div>
          </div>

          <div
            className={`input_container ${
              errors.email ? "error_container" : ""
            }`}
          >
            <label htmlFor="email">Email</label>
            <Input
              type="text"
              palceholder="Enter your email"
              name="email"
              id="email"
              register={register}
              error={errors.email ? errors.email : null}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>

          <div
            className={`input_container ${
              errors.password ? "error_container" : ""
            }`}
          >
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              palceholder="Enter your password"
              name="password"
              id="password"
              register={register}
              error={errors.password ? errors.password : null}
              show={showPassword}
              setShow={setShowPassword}
            />
            {errors.password && (
              <p className="error">{errors.password.message}</p>
            )}
          </div>

          <div
            className={`input_container ${
              errors.confirmPassword ? "error_container" : ""
            }`}
          >
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Input
              type="password"
              palceholder="Confirm your password"
              name="confirmPassword"
              id="confirmPassword"
              register={register}
              error={errors.confirmPassword ? errors.confirmPassword : null}
              show={showConfirmPassword}
              setShow={setShowConfirmPassword}
            />
            {errors.confirmPassword && (
              <p className="error">{errors.confirmPassword.message}</p>
            )}
          </div>

          <div
            className={`input_container ${
              errors.phone ? "error_container" : ""
            }`}
          >
            <label htmlFor="Date">Birth Date</label>
            <div className="input_container_date">
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
          </div>

          <div
            className={`input_container ${
              errors.country ? "error_container" : ""
            }`}
          >
            <label htmlFor="country">Country</label>
            <Input
              type="text"
              palceholder="Enter your country"
              name="country"
              id="country"
              register={register}
              error={errors.country ? errors.country : null}
            />
            {errors.country && (
              <p className="error">{errors.country.message}</p>
            )}
          </div>

          <div
            className={`input_container ${
              errors.city ? "error_container" : ""
            }`}
          >
            <label htmlFor="city">City</label>
            <Input
              type="text"
              palceholder="Enter your city"
              name="city"
              id="city"
              register={register}
              error={errors.city ? errors.city : null}
            />
            {errors.city && <p className="error">{errors.city.message}</p>}
          </div>

          <div
            className={`input_container ${
              errors.school ? "error_container" : ""
            }`}
          >
            <label htmlFor="school">School</label>
            <Input
              type="text"
              palceholder="Enter your school"
              name="school"
              id="school"
              register={register}
              error={errors.school ? errors.school : null}
            />
            {errors.school && <p className="error">{errors.school.message}</p>}
          </div>

          <div
            className={`input_container ${
              errors.phone ? "error_container" : ""
            }`}
          >
            <label htmlFor="phone">Phone</label>
            <PhoneInput
              country={"eg"}
              onChange={(value) => {
                setValue("phone", value);
              }}
              value={phoneValue}
              containerClass="custom-phone-input"
              inputClass="custom-phone-input"
              dropdownClass="custom-phone-input"
            />
            {errors.phone && <p className="error">{errors.phone.message}</p>}
          </div>

          <div
            className={`input_container ${
              errors.parentPhone ? "error_container" : ""
            }`}
          >
            <label htmlFor="parentPhone">Parent Phone</label>
            <PhoneInput
              country={"eg"}
              onChange={(value) => {
                setValue("parentPhone", value);
              }}
              value={parentPhoneValue}
              containerClass="custom-phone-input"
              inputClass="custom-phone-input"
              dropdownClass="custom-phone-input"
            />
            {errors.parentPhone && (
              <p className="error">{errors.parentPhone.message}</p>
            )}
          </div>

          <div className="input_container">
            <label>Gender :</label>
            <div className="redio_btns_container input_container_date">
              <div className="radio_input">
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  {...register("gender")}
                />
                <label htmlFor="male">Male</label>
              </div>
              <div className="radio_input">
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  {...register("gender")}
                />
                <label htmlFor="female">Female</label>
              </div>
            </div>
          </div>

          <button type="submit" className="submit_button" disabled={isLoading}>
            {isLoading ? <BeatLoader color="white" size={10} /> : "Register"}
          </button>

          <p className="login_option">
            Already have an account? <Link to={"/login"}>Login</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
