import React from 'react'
import PrivacyContainer from '../Components/PrivacyContainer/PrivacyContainer'

function Privacy() {
  return (
    <div>        <PrivacyContainer />
</div>
  )
}

export default Privacy