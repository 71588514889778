import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function getCourses(pageNum) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/courses?page=${pageNum}&size=9`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getCourse(token, courseId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/course?courseId=${courseId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getFinalExam(token, courseId, examIdx) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/course/questions?courseId=${courseId}&idx=${examIdx}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function attendFinalExam(token, courseId, userId, examIdx) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/course/attend?userId=${userId}&courseId=${courseId}&idx=${examIdx}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    console.log(data);

    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}
