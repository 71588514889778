import { Link, useLocation } from "react-router-dom";
import userIcon from "../../assets/images/userIcon.png";
import styles from "./ProfileNavbar.module.css";
import { FaRegUserCircle } from "react-icons/fa";
import { IoBookOutline, IoCopy, IoCopyOutline } from "react-icons/io5";
import { MdOutlineQuiz } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { RiLogoutCircleLine } from "react-icons/ri";
import { LuWallet } from "react-icons/lu";
import { useLogout } from "../../hooks/Auth/useLogout";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import { convertToBase64 } from "../../utils/helper";
import { useEditProfilePhoto } from "../../hooks/Auth/useEditProfilePhoto";
import { useApp } from "../../context/AppProvider";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ProfileNavbar() {
  const { token } = useApp();
  const { user } = useGetUser();
  const { editProfilePhoto, isLoading: isUploadingImage } =
    useEditProfilePhoto();
  const { logout } = useLogout();
  let currentPage = useLocation();
  currentPage = currentPage.pathname;

  const [selectedImg, setSelectedImg] = useState(null);

  const [copied, setCopied] = useState(false);

  function handleImageChange(e) {
    convertToBase64(e, (convertedImg) => {
      setSelectedImg(convertedImg);
    });
  }

  function handleUploadingImage(e) {
    e.preventDefault();
    const modal = {
      image: selectedImg,
    };
    editProfilePhoto(
      { userId: user.id, editImage: modal, token: token },
      {
        onSuccess: () => {
          setSelectedImg(null);
        },
      }
    );
  }

  return (
    <div className={`${styles.profile_navbar}`}>
      <div className={`${styles.info}`}>
        <div className={styles.image_container}>
          <img
            src={
              selectedImg !== null
                ? selectedImg
                : user.image !== "" && user.image !== null
                ? user.image
                : userIcon
            }
            alt="user"
          />
          <label htmlFor="image">
            <FaEdit />
          </label>
          <input
            type="file"
            id="image"
            style={{ display: "none" }}
            onChange={(e) => handleImageChange(e)}
          />
        </div>
        {selectedImg !== null && (
          <button
            disabled={isUploadingImage}
            onClick={(e) => handleUploadingImage(e)}
          >
            {isUploadingImage ? "Loading..." : "Set Image"}
          </button>
        )}
        <p>{user?.name}</p>
        <p className={`${styles.referralCode}`}>
          Referral Code :{" "}
          <span>
            {user.referralCode}{" "}
            <CopyToClipboard
              text={user.referralCode}
              onCopy={() => setCopied(true)}
            >
              <button disabled={copied}>{copied ? <IoCopy /> : <IoCopyOutline />}</button>
            </CopyToClipboard>{" "}
          </span>
        </p>
      </div>
      <div className={`${styles.links}`}>
        <Link to="/profile">
          <div
            className={`${styles.link} ${styles.first_link} ${
              currentPage === "/profile" ? styles.active_link : ""
            }`}
          >
            <span>
              <FaRegUserCircle />
            </span>
            <p>My Profile</p>
          </div>
        </Link>
        <Link to="/profile/quizes">
          <div
            className={`${styles.link} ${
              currentPage.includes("/profile/quizes") ? styles.active_link : ""
            }`}
          >
            <span>
              <MdOutlineQuiz />
            </span>
            <p>Progress</p>
          </div>
        </Link>
        <Link to="/profile/courses">
          <div
            className={`${styles.link} ${
              currentPage === "/profile/courses" ? styles.active_link : ""
            }`}
          >
            <span>
              <IoBookOutline />
            </span>
            <p>Subscriptions</p>
          </div>
        </Link>

        <Link to="/profile/wallet">
          <div
            className={`${styles.link} ${
              currentPage === "/profile/wallet" ? styles.active_link : ""
            }`}
          >
            <span>
              <LuWallet />
            </span>
            <p>Loyalty points</p>
          </div>
        </Link>
        <Link to="/profile/setting">
          <div
            className={`${styles.link} ${
              currentPage.includes("/profile/setting") ? styles.active_link : ""
            }`}
          >
            <span>
              <IoSettingsOutline />
            </span>
            <p>Settings</p>
          </div>
        </Link>
        <div
          className={`${styles.link} ${styles.last_link}`}
          onClick={() => logout()}
        >
          <span>
            <RiLogoutCircleLine />
          </span>
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
}
export default ProfileNavbar;
