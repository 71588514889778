import { useMutation } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import {
  packageCheckOut,
  packageCheckOutFromOutEgypt,
} from "../../services/packages";

export function useCheckoutPackage(isFromEgypt) {
  const { token } = useApp();
  const { mutate, isPending } = useMutation({
    mutationFn: ({
      packageId,
      courseId,
      userId,
      referralCode,
      WalletValue,
      isEgypt,
      promoCode,
    }) =>
      isFromEgypt
        ? packageCheckOut(
            token,
            packageId,
            courseId,
            userId,
            referralCode,
            WalletValue,
            isEgypt,
            promoCode
          )
        : packageCheckOutFromOutEgypt(
            token,
            packageId,
            courseId,
            userId,
            referralCode,
            WalletValue,
            isEgypt,
            promoCode
          ),
  });

  return { checkoutPackage: mutate, isLoading: isPending };
}
