import { useMutation } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import { attendLesson } from "../../services/lesson";

export function useAttendLessonQuiz() {
  const { token } = useApp();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ lessonId, userId, level }) =>
      attendLesson(token, lessonId, userId, level),
  });
  return { attendLessonQuiz: mutate, isLoading: isPending };
}
