import { Link, useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/images/arrow-left-solid.svg";
import arrowRight from "../../assets/images/arrow-right-solid.svg";
import styles from "./CoursesPagination.module.css";

function CoursesPagination({ page, numberOfPage, start }) {
  const navigete = useNavigate();

  function handleNextPrevious(to) {
    if (+page + to <= 0 || +page + to > numberOfPage) {
      return;
    }

    navigete(`/courses/page/${+page + to}`);
  }

  return (
    <div className="d-flex justify-content-center mt-5 btn_container">
      <nav aria-label="Page navigation example">
        <ul className="pagination align-items-center">
          {+page !== 1 && (
            <li className="page-item mx-2">
              <img
                src={arrowLeft}
                alt="arrow-left-solid"
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                onClick={() => handleNextPrevious(-1)}
              />
            </li>
          )}
          {
            <Link to={`/courses/page/${start}`}>
              <li
                className={`${styles.page_item} ${
                  String(start) === page ? styles.selected : ""
                }`}
              >
                {start}
              </li>
            </Link>
          }
          {start + 1 <= numberOfPage && (
            <Link to={`/courses/page/${start + 1}`}>
              <li
                className={`${styles.page_item} ${
                  String(start + 1) === page ? styles.selected : ""
                }`}
              >
                {start + 1}
              </li>
            </Link>
          )}
          {start + 2 <= numberOfPage && (
            <Link to={`/courses/page/${start + 2}`}>
              <li
                className={`${styles.page_item} ${
                  String(start + 2) === page ? styles.selected : ""
                }`}
              >
                {start + 2}
              </li>
            </Link>
          )}
          {+page !== numberOfPage && (
            <li className="page-item">
              <img
                src={arrowRight}
                alt="arrow-left-solid"
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                onClick={() => handleNextPrevious(1)}
              />
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
export default CoursesPagination;
