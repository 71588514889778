import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import { getPagesContent } from "../../services/pages";

export function useGetPagesContent() {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["pages"],
    queryFn: () => getPagesContent(token),
  });

  return { pagesContent: data, isLoading };
}
