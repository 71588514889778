import styles from "./ContactusContainer.module.css";
import { useGetPagesContent } from "../../hooks/pages/useGetPagesContent";
import Loader from "../Loader/Loader";

function ContactusContainer() {
  const { pagesContent, isLoading } = useGetPagesContent();
  if (isLoading) return <Loader />;
  return (
    <div className={`m-5 ${styles.container}`}>
      <div className={styles.contentContainer}>
        <div className={styles.title}>Contact Us</div>
        <div className={styles.content}>
          <div className={styles.row}>
            <p className={styles.subTitle}>E-mail : </p>
            <p>{pagesContent.contact.email}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.subTitle}>Phone : </p>
            <p>{pagesContent.contact.phone}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactusContainer;
