import styles from "./EditPasswordForm.module.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../Input/Input";
import { useEditPassword } from "../../hooks/Auth/useEditPassword";
import { useApp } from "../../context/AppProvider";
import { BeatLoader } from "react-spinners";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import Loader from "../Loader/Loader";

function EditPasswordForm() {
  const schema = yup.object({
    currentPassword: yup
      .string()
      .required("Please enter your password")
      .min(8, "Password Must be At Least 8 Characters"),
    newPassword: yup
      .string()
      .required("Please enter your password")
      .min(8, "Password Must be At Least 8 Characters"),
    confirmPassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("newPassword")], "Passwords Must Match"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { editPassword, isLoading } = useEditPassword();
  const { token } = useApp();
  const { user, isLoading: isGetUser } = useGetUser();

  function onSubmit(data) {
    editPassword(
      {
        password: {
          userId: user.id,
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
        token,
      },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  }

  if (isGetUser) return <Loader type="mini" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`${styles.input_container}`}>
        <label htmlFor="currentPassword">Current Password</label>
        <Input
          type="text"
          palceholder="Enter current password"
          name="currentPassword"
          id="currentPassword"
          register={register}
          error={errors.currentPassword ? errors.currentPassword : null}
        />
        {errors.currentPassword && (
          <p className={`${styles.error}`}>{errors.currentPassword.message}</p>
        )}
      </div>

      <div className={`${styles.input_container}`}>
        <label htmlFor="newPassword">New Password</label>
        <Input
          type="text"
          palceholder="Enter New Password"
          name="newPassword"
          id="newPassword"
          register={register}
          error={errors.newPassword ? errors.newPassword : null}
        />
        {errors.newPassword && (
          <p className={`${styles.error}`}>{errors.newPassword.message}</p>
        )}
      </div>

      <div className={`${styles.input_container}`}>
        <label htmlFor="confirmPassword">Confirm Password</label>
        <Input
          type="text"
          palceholder="Enter Confirm New Password"
          name="confirmPassword"
          id="confirmPassword"
          register={register}
          error={errors.confirmPassword ? errors.confirmPassword : null}
        />
        {errors.confirmPassword && (
          <p className={`${styles.error}`}>{errors.confirmPassword.message}</p>
        )}
      </div>

      <button
        type="submit"
        className={`${styles.submit_button}`}
        disabled={isLoading}
      >
        {isLoading ? <BeatLoader color="white" size={10} /> : "Update Password"}
      </button>
    </form>
  );
}
export default EditPasswordForm;
