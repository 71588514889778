import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "../../services/Auth";
import toast from "react-hot-toast";

export function useResetPassword() {
  const { mutate, isPending } = useMutation({
    mutationFn: ({ userEmail, otp, newPassword }) =>
      resetPassword(userEmail, otp, newPassword),
    onSuccess: () => {
      toast.success("Password reset successfully");
    },
    onError: (error) => toast.error(error.message),
  });

  return { resetPassword: mutate, isLoading: isPending };
}
