import React from "react";
import AboutusContainer from "../Components/AboutusContainer/AboutusContainer";

function Aboutus() {
  return (
    <div>
      <AboutusContainer />
    </div>
  );
}

export default Aboutus;
