import { dateFormat } from "../../utils/helper";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import styles from "./ProfileInfo.module.css";

function ProfileInfo() {
  const { user } = useGetUser();

  return (
    <div className={`${styles.profile_info_container}`}>
      <div className={`${styles.header}`}>
        <p>My Profile</p>
      </div>
      <div className={`${styles.profile_info}`}>
        <div className={`${styles.titles}`}>
          <p>Name</p>
          <p className={`${styles.value}`}>{user.name}</p>
          <p>Email</p>
          <p className={`${styles.value}`}>{user.email}</p>
          <p>Gender</p>
          <p className={`${styles.value}`}>{user.gender}</p>
          <p>Birth Date</p>
          <p className={`${styles.value}`}>{user.birthday}</p>
          <p>Phone Number</p>
          <p className={`${styles.value}`}>+{user.phone}</p>
          <p>Parent Phone</p>
          <p className={`${styles.value}`}>+{user.parentPhone}</p>
          <p>Registeration Date</p>
          <p className={`${styles.value}`}>{dateFormat(user.createdDate)}</p>
          <p>Country</p>
          <p className={`${styles.value}`}>{user.country}</p>
          <p>City</p>
          <p className={`${styles.value}`}>{user.city}</p>
          <p>School</p>
          <p className={`${styles.value}`}>{user.school}</p>
        </div>
      </div>
    </div>
  );
}
export default ProfileInfo;
