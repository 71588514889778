import { Outlet } from "react-router-dom";
import ProfileNavbar from "../ProfileNavbar/ProfileNavbar";
import styles from "./ProfileContainer.module.css";
import ProfileHeader from "../ProfileHeader/ProfileHeader";

function ProfileContainer() {
  return (
    <div>
      <div className={`${styles.header_container}`}>
        <ProfileHeader />
      </div>
      <div className={`${styles.profile_container} my-5`}>
        <div className={`${styles.navbar_container}`}>
          <ProfileNavbar />
        </div>
        <div className={`${styles.page_container}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default ProfileContainer;
