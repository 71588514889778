import { createContext, useContext } from "react";
import { useLocalStorageState } from "../hooks/useLocalStorageState";

const AppContext = createContext();

function AppProvider({ children }) {
  const [token, setToken] = useLocalStorageState(null, "token");

  return (
    <AppContext.Provider value={{ token, setToken }}>
      {children}
    </AppContext.Provider>
  );
}

const useApp = () => {
  let context = useContext(AppContext);
  if (context === undefined) throw new Error("provider Error");
  return context;
};

export { AppProvider, useApp };
