import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import Question from "../Question/Question";
import QuestionsPagination from "../QuestionsPagination/QuestionsPagination";
import styles from "./QuizContainer.module.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useGetExam } from "../../hooks/quiz/useGetExam";
import { useGetExamAnswer } from "../../hooks/quiz/useGetExamAnswer";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import Timer from "../Timer/Timer";
import { useSubmitFinalExamAnswer } from "../../hooks/quiz/useSubmitFinalExamAnswer";
import toast from "react-hot-toast";

function QuizContainer() {
  const {
    courseId,
    lessonId,
    status,
    level,
    time: timePerMinutes,
    examIdx,
  } = useParams();

  const navigate = useNavigate();
  const [time, setTime] = useState(timePerMinutes * 60);
  const { user } = useGetUser();
  const location = useLocation();
  const pathName = location.pathname;
  const { exam, isLoading: isLoadingExam } = useGetExam(
    pathName.includes("/courses/course/lesson/quiz/") ||
      pathName.includes("/courses/course/lesson/quizFreeTrier/")
      ? lessonId
      : courseId,
    pathName.includes("/courses/course/lesson/quiz/")
      ? "quiz"
      : pathName.includes("/courses/course/lesson/quizFreeTrier/")
      ? "quizFreeTrier"
      : "finalExam",
    pathName.includes("/courses/course/lesson/quiz/") ? level : "",
    examIdx
  );
  const { examAnswer, isLoading: isLoadingAnswer } = useGetExamAnswer(
    pathName.includes("/courses/course/lesson/quiz/") ? lessonId : courseId,
    user.id,
    "quiz",
    level
  );

  const { submitAnswer: submitAnswerFinalExam } = useSubmitFinalExamAnswer();

  const [questionIndex, setQuestionIndex] = useState(1);
  const [answers, setAnswers] = useState([]);

  const isLoading = isLoadingAnswer || isLoadingExam;

  function handleNextPrevious(to) {
    if (questionIndex + to <= 0 || questionIndex + to > exam?.length) {
      return;
    }

    setQuestionIndex((e) => e + to);
  }

  useEffect(
    function () {
      if (timePerMinutes !== undefined && time === 0) {
        const answersModel = {
          userId: user.id,
          courseId: courseId,
          questionsAnswers: answers,
        };

        submitAnswerFinalExam(
          { answers: answersModel, examIdx: examIdx },
          {
            onSuccess: (data) => {
              toast.success("Submit Practice Exam Answers Successfully");
              navigate(`/examAnswer/${courseId}`);
            },
          }
        );
      }
    },
    [
      time,
      answers,
      courseId,
      user.id,
      navigate,
      submitAnswerFinalExam,
      pathName,
      timePerMinutes,
      examIdx,
    ]
  );

  useEffect(
    function () {
      if (isLoading) return;

      if (status) {
        switch (status) {
          case "right":
            const tmp1 = examAnswer?.lessonQuestions?.filter(
              (question) => question.isCorrect === true
            );
            setAnswers(tmp1);
            break;
          case "wrong":
            const tmp2 = examAnswer?.lessonQuestions?.filter(
              (question) =>
                question.isCorrect === false && question.userAnswer !== ""
            );
            setAnswers(tmp2);
            break;
          case "notSolved":
            const tmp3 = examAnswer?.lessonQuestions?.filter(
              (question) => question.userAnswer === ""
            );
            setAnswers(tmp3);
            break;
          default:
            break;
        }

        return;
      }

      if (pathName.includes("/courses/course/lesson/quiz/") && !status) {
        setAnswers(examAnswer?.lessonQuestions);
        return;
      }

      if (exam) {
        if (pathName.includes("/courses/course/lesson/quiz/")) return;
        let tmp = [];
        exam.map(
          (question) =>
            (tmp = [
              ...tmp,
              {
                questionId: question.id,
                isCorrect: false,
                question: question.question,
                correctAnswer: question.correctAnswer,
                userAnswer: "",
                explaination: question.explanation,
                options: question.options,
                image: question.image,
              },
            ])
        );

        setAnswers(tmp);
      }
    },
    [exam, pathName, status, examAnswer?.lessonQuestions, isLoading, examAnswer]
  );

  if (isLoading || answers.length === 0) return <Loader />;

  return (
    <div className={`${styles.quiz_container}`}>
      <div className={styles.question_container}>
        {timePerMinutes !== undefined && (
          <div className={styles.timer}>
            <Timer time={time} setTime={setTime} />
          </div>
        )}
        <Question
          key={answers?.[questionIndex - 1]?.questionId}
          examIdx={examIdx}
          question={
            examAnswer?.lessonQuestions?.[questionIndex - 1]?.userAnswer ===
              "" || examAnswer === undefined
              ? exam[questionIndex - 1]
              : answers[questionIndex - 1]
          }
          answers={answers}
          setAnswers={setAnswers}
          questionIndex={questionIndex - 1}
          type={
            pathName.includes("/courses/course/lesson/quizFreeTrier/")
              ? "quiz"
              : pathName.includes("/courses/course/lesson/quiz/")
              ? answers?.[questionIndex - 1]?.userAnswer === ""
                ? "quiz"
                : "quizAnswred"
              : "finalExam"
          }
          id={lessonId}
        />
      </div>
      <div className={`${styles.btns}`}>
        {questionIndex !== 1 && (
          <button onClick={() => handleNextPrevious(-1)}>
            <span>
              <FaArrowLeftLong />
            </span>{" "}
          </button>
        )}
        <QuestionsPagination
          numberOfPage={answers?.length}
          start={
            questionIndex % 5 === 1
              ? questionIndex
              : Math.max(
                  1,
                  questionIndex -
                    (questionIndex % 5) +
                    (questionIndex % 5 === 0 ? -4 : 1)
                )
          }
          indexOfQuestion={questionIndex}
          setQuestionIndex={setQuestionIndex}
          answers={answers}
          type={
            pathName.includes("/courses/course/lesson/quiz/") ? "quiz" : "any"
          }
        />
        {questionIndex !== answers?.length && (
          <button onClick={() => handleNextPrevious(1)}>
            <span>
              <FaArrowRightLong />
            </span>{" "}
          </button>
        )}
      </div>
    </div>
  );
}
export default QuizContainer;
