import { useGetUser } from "../../hooks/Auth/useGetUser";
import styles from "./ProfileWallet.module.css";
import { BsCoin } from "react-icons/bs";
import walletImg from "../../assets/images/walletImg.jpeg"
import { Col, Container, Row } from "react-bootstrap";
const ProfileWallet = () => {
  const { user } = useGetUser();
  
  return (
    <div className={`${styles.profile_wallet_container}`}>
      <div className={`${styles.header}`}>
        <p>Loyalty points</p>
      </div>

<Container fluid>
  <Row>
    <Col md={8}>
    <div className={`${styles.wallet_contant}`}>
        <span>
          <BsCoin />
        </span>
        <p>
        Loyalty points : <span>{user.wallet}</span>
        </p>
      </div>
    </Col>
    <Col md={4}>
    <img src={walletImg}alt="wallet" className="w-100"/>
    </Col>
  </Row>
</Container>
    </div>
  );
};
export default ProfileWallet;
