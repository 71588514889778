import { useMutation } from "@tanstack/react-query";
import { editPassword } from "../../services/Auth";
import toast from "react-hot-toast";

export function useEditPassword() {
  const { mutate, isPending } = useMutation({
    mutationFn: ({ password, token }) => editPassword(password, token),
    onSuccess: () => {
      toast.success("Edit Password successfully");
    },
    onError: (error) => toast.error(error.message),
  });

  return { editPassword: mutate, isLoading: isPending };
}
