import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import { getPackages } from "../../services/packages";

export function useGetPackages() {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["packages"],
    queryFn: () => getPackages(token),
  });

  return { packages: data, isLoading };
}
