import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useApp } from "../../context/AppProvider";

function ProtectRouter({ children }) {
  let currentPage = useLocation();
  currentPage = currentPage.pathname;
  const navigate = useNavigate();
  const { token } = useApp();
  const isAuth = token !== null;
  useEffect(
    function () {
      if (
        currentPage.includes("/profile") ||
        currentPage.includes("/packages") ||
        currentPage.includes("/courses/course") ||
        currentPage.includes("/examAnswer") ||
        currentPage.includes("/checkout")
      ) {
        if (!isAuth) {
          navigate("/login");
          toast.error("Please Login First !");
        }
      }

      if (
        currentPage === "/login" ||
        currentPage === "/register" ||
        currentPage === "/forgetPassword" ||
        currentPage.includes("/resetPassword")
      ) {
        if (isAuth) {
          navigate("/");
        }
      }
    },
    [currentPage, navigate, isAuth]
  );
  return children;
}
export default ProtectRouter;
