import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setLessonQiuzQuestionAnswer } from "../../services/quiz";
import { useApp } from "../../context/AppProvider";

export function useSubmitQuizQuestion() {
  const queryClient = useQueryClient();
  const { token } = useApp();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ answers, userId, lessonId , level}) =>
      setLessonQiuzQuestionAnswer(token, answers, userId, lessonId , level),
    onSuccess: () => {
      queryClient.invalidateQueries(["examAnswer"]);
    },
  });
  return { submitAnswer: mutate, isLoading: isPending };
}
