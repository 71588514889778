import styles from "./ProfileQuizes.module.css";
import { Link, Outlet, useLocation } from "react-router-dom";

function ProfileQuizes() {
  let currentPage = useLocation();
  currentPage = currentPage.pathname;

  return (
    <div className={`${styles.profile_quizes_container}`}>
      <div className={`${styles.header}`}>
        <p>Enrolled Quizes</p>
      </div>

      <div className={`${styles.btns}`}>
        <Link
          to="/profile/quizes"
          className={`${
            currentPage === "/profile/quizes" ? styles.active_link : ""
          }`}
        >
          Lessons
        </Link>
        <Link
          to="/profile/quizes/finalExams"
          className={`${
            currentPage === "/profile/quizes/finalExams"
              ? styles.active_link
              : ""
          }`}
        >
          Practice Exams
        </Link>
      </div>

      <Outlet />
    </div>
  );
}
export default ProfileQuizes;
