import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function getLessons(token, chapterId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/chapter/${chapterId}/lessons?chapterId=${chapterId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function attendLesson(token, lessonId, userId, level) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/lesson/attend?userId=${userId}&lessonId=${lessonId}&level=${level}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getLessonQuiz(token, lessonId, level) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/lesson/questions?lessonId=${lessonId}&level=${level}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getLessonQuizFreeQuestion(token, lessonId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/lesson/questions/free?lessonId=${lessonId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}
