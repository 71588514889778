import styles from "./FAQContainer.module.css";
import { useGetPagesContent } from "../../hooks/pages/useGetPagesContent";
import Loader from "../Loader/Loader";
import Accordion from 'react-bootstrap/Accordion';

function FAQContainer() {
    const { pagesContent, isLoading } = useGetPagesContent();
    if (isLoading) return <Loader />;

    return (
        <div className={`m-5 ${styles.FAQContainer}`}>
            <div className={styles.contentContainer}>
                <div className={styles.title}>FAQ</div>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <p className="mb-0">            Are there English courses available on Mathapes?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </Accordion.Header>
                        <Accordion.Body>
                        <p className="mb-0"> 
                            No, Mathapes specializes in math-related courses. English language courses are not offered on our platform
                     </p>
        
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            Is there a free trial available?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            </Accordion.Header>
                        <Accordion.Body>
                        <p className="mb-0"> 
                            Yes, you can access some sample questions and a practice exam at no cost after registration.
                            </p>
                       
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            What devices is your platform compatible with?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            </Accordion.Header>
                        <Accordion.Body>
                            Mathapes is compatible with computers, tablets and mobiles.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            Can I revisit and analyze my past practice test mistakes?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </Accordion.Header>
                        <Accordion.Body>
                            Yes, you have the ability to review all of your work and saved progress, allowing you to pinpoint areas where you may have made mistakes and strengthen your understanding.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            Is it possible to redo the practice tests and the lesson exercises? 
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                            </Accordion.Header>
                        <Accordion.Body>
                            Absolutely! You can retake practice tests and exercises as many times as you need. Please keep in mind that your previous results and scores will be reset when you retake them.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            Can I download or print the material on the website? 
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>

                                   </Accordion.Header>
                        <Accordion.Body>
                            While you can access the materials online, downloading or printing them is prohibited. Any violations of this policy may result in the termination of your account.        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            Where can I find my referral code after signing up?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>

                        </Accordion.Header>
                        <Accordion.Body>
                            Your referral code will be provided to you in your profile after you complete the registration process.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            How does the referral program work?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>

                        </Accordion.Header>
                        <Accordion.Body>
                            When you refer a friend to Mathapes using your unique referral code, you'll receive a onetime bonus to your account wallet that can be used for future subscriptions.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            Is there a time limit on using promo codes?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </Accordion.Header>
                        <Accordion.Body>
                            Yes, our promo codes have a limited lifespan.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>
                        <p className="mb-0"> 
                            What payment methods do you accept?
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </Accordion.Header>
                        <Accordion.Body>
                            We offer a variety of payment options. You can pay with all major credit and debit cards, as well as popular electronic wallets.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>)
}

export default FAQContainer