import "./CoursesContainer.css";
import CourseCard from "../CourseCard/CourseCard";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useGetCourses } from "../../hooks/courses/useGetCourses";
import Loader from "../Loader/Loader";
import CoursesPagination from "../CoursesPagination/CoursesPagination";
function CoursesContainer({ pagination }) {
  const { page } = useParams();
  const { courses, isLoading } = useGetCourses(page - 1 || 0);
  if (isLoading) return <Loader />;
  
  return (
    <section className={`px-3 py-5 CoursesContainer my-5`}>
      <Container fluid>
        <Row>
          {!pagination ? (
            <Col md={12}>
              <div className="mb-4 text-center">
                <h2 className="font-weight-bold">
                  Our <span>Courses</span>
                </h2>
        
              </div>
            </Col>
          ) : (
            <Col md={12}>
              <div className="mb-4 text-center">
                <h2 className="font-weight-bold">Explore All Courses</h2>
              </div>
            </Col>
          )}
          {courses.content.map((item) => {
            return (
              <Col md={4} key={item.id}>
                <CourseCard course={item} />
              </Col>
            );
          })}
          {!pagination ? (
            <Col md={12}>
              <div className="d-flex justify-content-center mt-5 btn_container">
                <div>
                  <Link to={"/courses"}>Explore All Courses</Link>
                </div>
              </div>
            </Col>
          ) : (
            <Col md={12}>
              <CoursesPagination
                page={page || "1"}
                numberOfPage={courses.totalPages}
                start={
                  !page
                    ? 1
                    : +page % 3 === 1
                    ? +page
                    : +page % 3 === 0
                    ? +page - 2
                    : +page - 1
                }
              />
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default CoursesContainer;
