import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import Question from "../Question/Question";
import QuestionsPagination from "../QuestionsPagination/QuestionsPagination";
import styles from "./FreeQuizContainer.module.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useGetExam } from "../../hooks/quiz/useGetExam";

function FreeQuizContainer() {
  const { lessonId } = useParams();

  const { exam, isLoading: isLoadingExam } = useGetExam(
    lessonId,
    "quizFreeTrier",
    ""
  );
  const [questionIndex, setQuestionIndex] = useState(1);
  const [answers, setAnswers] = useState([]);

  const isLoading = isLoadingExam;

  function handleNextPrevious(to) {
    if (questionIndex + to <= 0 || questionIndex + to > exam?.length) {
      return;
    }

    setQuestionIndex((e) => e + to);
  }

  useEffect(
    function () {
      if (isLoading) return;

      let tmp = [];

      exam.map(
        (question) =>
          (tmp = [...tmp, { ...question, userAnswer: "", isCorrect: false }])
      );

      setAnswers(tmp);
    },
    [exam, isLoading]
  );

  if (isLoading || answers.length === 0) return <Loader />;

  return (
    <div className={`${styles.quiz_container}`}>
      <Question
        key={answers?.[questionIndex - 1]?.id}
        question={answers[questionIndex - 1]}
        answers={answers}
        setAnswers={setAnswers}
        questionIndex={questionIndex - 1}
        type={
          answers?.[questionIndex - 1]?.userAnswer === ""
            ? "freeQuiz"
            : "freeQuizAnswered"
        }
        id={lessonId}
      />
      <div className={`${styles.btns}`}>
        {questionIndex !== 1 && (
          <button onClick={() => handleNextPrevious(-1)}>
            <span>
              <FaArrowLeftLong />
            </span>{" "}
          </button>
        )}
        <QuestionsPagination
          numberOfPage={answers?.length}
          start={
            questionIndex % 5 === 1
              ? questionIndex
              : Math.max(
                  1,
                  questionIndex -
                    (questionIndex % 5) +
                    (questionIndex % 5 === 0 ? -4 : 1)
                )
          }
          indexOfQuestion={questionIndex}
          setQuestionIndex={setQuestionIndex}
          answers={answers}
          type={"quiz"}
        />
        {questionIndex !== answers?.length && (
          <button onClick={() => handleNextPrevious(1)}>
            <span>
              <FaArrowRightLong />
            </span>{" "}
          </button>
        )}
      </div>
    </div>
  );
}
export default FreeQuizContainer;
