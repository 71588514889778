import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import arrow_icon from "../../assets/images/arrow icon.svg";
import logo from "../../assets/images/logo.jpg";
import facebookIcon from "../../assets/images/facebook.png";
import instaIcon from "../../assets/images/instagram.png";
import youtubeIcon from "../../assets/images/youtube.png";
import titokIcon from "../../assets/images/tiktok.png";

function Footer() {
  return (
    <section className="px-3 footer_container">
      <div className=" py-5">
        <Container>
        <p className="mb-0 mt-4 mb-5 fs-1">
                All test names and other trademarks are the property of their respective owners. Mathapes and this website are not associated with or endorsed by any of these companies."
                </p>
          <Row>
            <Col md={8}>
              <div className="footer_paragraph">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <img
                      alt="logo"
                      src={logo}
                      style={{
                        width: "75px",
                        height: "75px",
                        borderRadius: "50%",
                      }}
                    />
                    <p className="mb-0 mx-3">
                    Mathapes
                    </p>
                  </div>
                </Link>
          
                <div className="social_media_icons">
                  <Link
                    to="https://www.facebook.com/profile.php?id=61564649316168&mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <img src={facebookIcon} alt="facebook" />
                  </Link>
                  <Link
                    to="https://youtube.com/@mathapes?si=1pcT8jJoNy687Bsw"
                    target="_blank"
                  >
                    <img src={youtubeIcon} alt="youtube" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/math_apes?igsh=MTN6a2NlamVyM2xobA=="
                    target="_blank"
                  >
                    <img src={instaIcon} alt="instagram" />
                  </Link>
                  <Link
                    to="https://www.tiktok.com/@math_apes?_t=8p6xRL4rEN6&_r=1"
                    target="_blank"
                  >
                    <img src={titokIcon} alt="tiktok" />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <ul className="px-0">
                <li className="footer-link">
                  <Link to="/">
                    <div className="d-flex align-items-center mb-2">
                      <img
                        alt="arrow_icon"
                        src={arrow_icon}
                        style={{ width: "25px" }}
                      />
                      <p className="mb-0">Home</p>
                    </div>
                  </Link>
                </li>
         
                <li className="footer-link">
                  <Link to="/aboutus">
                    <div className="d-flex align-items-center mb-2">
                      <img
                        alt="arrow_icon"
                        src={arrow_icon}
                        style={{ width: "25px" }}
                      />
                      <p className="mb-0">About us</p>
                    </div>
                  </Link>
                </li>
            
                <li className="footer-link">
                  <Link to="/contactus">
                    <div className="d-flex align-items-center mb-2">
                      <img
                        alt="arrow_icon"
                        src={arrow_icon}
                        style={{ width: "25px" }}
                      />
                      <p className="mb-0">Contact us</p>
                    </div>
                  </Link>
                </li>
                <li className="footer-link">
                  <Link to="/privacy">
                    <div className="d-flex align-items-center mb-2">
                      <img
                        alt="arrow_icon"
                        src={arrow_icon}
                        style={{ width: "25px" }}
                      />
                      <p className="mb-0">Privacy</p>
                    </div>
                  </Link>
                </li>
                <li className="footer-link">
                  <Link to="/terms">
                    <div className="d-flex align-items-center mb-2">
                      <img
                        alt="arrow_icon"
                        src={arrow_icon}
                        style={{ width: "25px" }}
                      />
                      <p className="mb-0">Terms</p>
                    </div>
                  </Link>
                </li>
                <li className="footer-link">
                  <Link to="/faq">
                    <div className="d-flex align-items-center mb-2">
                      <img
                        alt="arrow_icon"
                        src={arrow_icon}
                        style={{ width: "25px" }}
                      />
                      <p className="mb-0">FAQ</p>
                    </div>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-center py-3 border-top ">
        <p>
          2024 <span className="font-weight-bold">Mathapes</span> . All
          rights reserved
        </p>
      </div>
    </section>
  );
}

export default Footer;