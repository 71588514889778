import styles from "./PrivacyContainer.module.css";
import { useGetPagesContent } from "../../hooks/pages/useGetPagesContent";
import Loader from "../Loader/Loader";
import Accordion from 'react-bootstrap/Accordion';

export default function PrivacyContainer() {
    const { pagesContent, isLoading } = useGetPagesContent();
    if (isLoading) return <Loader />;

  return (
    <div className={`m-5 ${styles.privacyContainer}`}>
    <div className={styles.contentContainer}>
      <div className={styles.title}>Privacy Notice
      </div>
      <div className={styles.content}>
      Overview
This Privacy Notice explains how information is collected, used, stored, and disclosed by Mathapes. It applies to information collected when you use or access our public website (Mathapes.com)
Changing Our Notice 
We may change this Privacy Notice from time to time. If we make any changes, we will notify you by revising the "Last Updated" date at the top of this Privacy Notice and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you an email notification).
If there are material changes to this Privacy Notice, we will notify you directly by email or by means of a notice on the homepage prior to the change becoming effective. We encourage you to review our Privacy Notice whenever you access the Mathapes Service to stay informed about our information practices and the ways that you can help protect your privacy.
If our information practices change at some time in the future we will post the policy changes to our public websites to notify you of these changes and provide you with the ability to opt out of these new uses. If you no longer agree with this Privacy Notice and do not wish your information to be subject to the Privacy Notice, you will need to deactivate your account with us and stop using the Mathapes Service. Your use of the Mathapes Service shall constitute your consent to the present Privacy Notice.
What Information We Collect
We may collect certain user information, including personal data.
Information You Provide To Us. We collect information you provide directly to us including when you use the Mathapes Service. This information includes personal data. This personal data will be processed for the purposes described in this Privacy Notice, as it may be amended from time to time. For example, your personal data will be used to create or modify your profile and account, access and use the Mathapes Service (including but not limited to when you answer practice questions, or take practice exams), participate in any interactive features of the Mathapes Service, submit a contact or consent form, participate in a survey, or request customer support.
The personal data that we may collect directly from you includes your name, username, email address, phone number, information about your Product account preferences, transactional information (including services purchased or subscribed to and amount paid), as well as any contact or other information you choose to provide.
The personal data provided during payment is collected and processed by our payment processor. Mathapes does not save any personal financial information, including credit card numbers, expiration dates, security codes, or postal codes.


Updating Your Information and Deactivating Your Account. You can update, correct, or modify your account information at any time by logging into your Mathapes Product account and accessing your profile page. If you need help accessing, modifying, or deactivating your account within the Product, please contact us. 
Information We Collect Automatically When You Use the Mathapes Service. When you access or use the Mathapes Service, we may automatically collect information, including personal data, about you, including:
Usage, Log and Device Information: We collect information from your use of the Mathapes Service, such as number of visits, length of time spent using or accessing our Products, IP address (which may allow us to collect general location data), pages clicked on, system activity, hardware settings, device type, operating system, browser type, browser language, the date and time of your visit, and the URL you were on before you came to the our website. We collect information on user activity in connection with the Mathapes Service and may collect information about the features you use, the content you access, and any actions taken in connection with your access and use of the Mathapes Service.
Information Collected by Cookies and Other Tracking Technologies: A cookie is a small file containing a string of characters that is sent to your computer when you visit a website. When you visit the website again, the cookie allows that site to recognize your browser. Cookies may store user preferences and other information. You can set your browser to refuse all cookies or to indicate when a cookie is being sent. However, some website features or services may not function properly without cookies.
Our third-party vendors, including Google, use tracking technologies on web browsers in order to serve ads based on past visits to our Products, report on the performance of our ads, conduct analytics on the use of our Products, fulfill orders and other user requests, prevent fraud, improve our Products and conduct surveys relating to our users’ experiences with our Products, and communicate with our users. 
How We Use Information Collected
We use the information collected through the Mathapes Service in order to facilitate provision of the Mathapes Service and engage with you about our products and offerings. We may use the information that we collect:
To provide, operate, maintain, and improve the Mathapes Service;
To enable you to access and use the Mathapes Service that you request, including sending you technical notices, updates, alerts, and support and administrative messages;
To provide and deliver the services and features you request, process and complete transactions, and send you related information, including purchase confirmations and receipts;
To respond to your comments, questions, and requests, and provide customer service and support;
To communicate with you about services, features, surveys, newsletters, offers, promotions, events, and provide other news or information about Mathapes and our select partners;
To process and deliver survey entries and rewards;
To review and analyze trends, usage, and activities in connection with the Mathapes Service and for marketing or advertising purposes;
To investigate and prevent fraudulent transactions, unauthorized access to the Mathapes Service, and other illegal activities;
To personalize and improve the Mathapes Service, and provide content and/or features that match your interests and preferences or otherwise Mathapes your experience on the Mathapes Service; and
For other purposes about which we notify you in advance or for which we receive your consent, as necessary.
Social media network credentials enable them and us to link your activities with your personal registration information at their site, including name, email address, and profile image. The social network may (with your permission) notify your friends or connections about your activities in our Services and use tracking technologies to monitor your online activities to serve targeted ads. The social media networks' privacy policy and terms apply to your use of their platforms. Your browser or device may allow you to block these technologies but you should visit the third party website and review their privacy policy and your registration profile or account to find out your options.
How We Use Cookies
We use cookies to understand and save your preferences for future visits and compile aggregate data about Product traffic and Product interaction so that we can offer better Product experiences and tools in the future.
We may contract with third-party service providers to assist us to serve ads based on past visits to our Products, report on the performance of our ads, conduct analytics on the use of our Products, fulfill orders and other user requests, fraud prevention, improve our Products and conduct surveys relating to our users’ experiences with our Products, and communicate with our users. For instance, we use Google Analytics Demographics and Interest Reporting, which includes data from interest-based advertising or third-party audience data (such as age, gender, and interests), for all of the purposes listed above. Third party vendors, including Google, use cookies to serve ads based on a user’s prior visits to our Products. This allows us to make special offers and continue to market our services to those who have shown interest in our service. 
Data Retention and Erasure
We will maintain certain data for a period of 7 years from the date of your last log in or other study activity in order to keep accurate records for tax purposes. After 7 years from your last activity, we will permanently delete all such personally identifying information. We will keep all other personally identifying information for at least one year after your last login, but in no event longer than seven years. Regardless of the retention period described in this section, we may be required to retain data for longer to comply with legal, regulatory, or audit obligations. Other historical study information may be anonymized and kept at Mathapes’s discretion.
What Information We Share or Disclose to Others
We will not share personal data about you with any third parties except as described in this Privacy Notice, or as defined in an agreement with us in connection with the Mathapes Service (including a Data Processing Addendum, as applicable). Information that may be shared, pursuant to any applicable agreements, includes:
Third-Party Service Providers. We may share your information with third-party service providers who are working on our behalf and require access to your information to carry out that work, such as to process payment or as it relates to a Product feature. These service providers are authorised to use your personal data only as necessary to provide services to Mathapes. 
Compliance with Laws. We may disclose your information to a third party: (a) if we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process, or government request; (b) to enforce our agreements and policies; (c) to protect the security or integrity of the Mathapes Service; (d) to protect Mathapes, our customers, or the public from harm or illegal activities; (e) to respond to an emergency which we believe in good faith requires us to disclose information to assist in preventing the death or serious bodily injury of any person; or (f) as otherwise directed by you.
Business Transfers. We may share or transfer your personal data in connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. Your personal data may be part of the transferred assets. Data processing for this purpose is a legitimate business interest. You may be notified thereafter of any such change in ownership or control.
Aggregated and/or Anonymized Data. We may also share aggregated and/or anonymized information that does not directly identify you individually with third parties. For example, in an ongoing effort to better understand and serve the users of the Mathapes Service, we may conduct research on customer demographics, interests and behavior based on the personal information, interviews, and other data provided to us. This research may be compiled and analyzed on an aggregate basis, and we may share this aggregated data with our agents and business partners. This aggregated information does not identify you personally. We may also disclose aggregated user statistics in order to describe the Mathapes Service to current and prospective business partners, and to other third parties for other lawful purposes.
Links To Third-Party Websites
We may place links on the Mathapes Service, including the Mathapes Blog. When you click on a link from our Product to a third-party website, your activity and use is governed by that website’s policies; not by those of Mathapes. We encourage you to review their privacy and user policies.
How We Store Information
By using and accessing the Mathapes Service, you understand and agree to the storage of information and any other personal data on Mathapes’s server and cloud-based solutions located in the United States, or as defined in an agreement with us (including a Data Processing Addendum, as applicable).
How We Secure Information
We have adopted reasonable physical, technical and organizational safeguards against accidental, unauthorized or unlawful destruction, loss, alteration, disclosure, access, use or processing of information we collect.
You may access your Product account information and our Product only through the use of an individual user ID and password. To protect the confidentiality of personal data and information, you must keep your password confidential and not disclose it to any other person. To keep your account secure, always log out and close your browser when you finish your session. Please advise us immediately if you believe your password has been compromised in any way. You may also select the “Log out of all sessions” option on your profile page to close all other browser sessions for your account. Please note that we will never ask you to disclose your password to us.
Unfortunately, the transmission of information via the Internet and apps is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to us. Any transmission of personal information is at your own risk. We are not responsible for any circumvention of any privacy settings or security measures.
If you have any questions about the security of your personal data, you can contact us.

      </div>
    </div>
  </div>
  )
}
