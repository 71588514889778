import styles from "./QuestionsPagination.module.css";

function QuestionsPagination({
  numberOfPage,
  start,
  indexOfQuestion,
  setQuestionIndex,
  answers,
  type,
}) {
  return (
    <div className={`${styles.container}`}>
      {start <= numberOfPage && (
        <div
          className={`${styles.circle} ${
            indexOfQuestion === start ? styles.selected : ""
          }
          ${
            type === "quiz"
              ? answers[start - 1].isCorrect
                ? styles.correct
                : answers[start - 1].userAnswer !== ""
                ? styles.wrong
                : ""
              : ""
          }`}
          onClick={() => setQuestionIndex(start)}
        >
          {start}
        </div>
      )}
      {start + 1 <= numberOfPage && (
        <div
          className={`${styles.circle} ${
            indexOfQuestion === start + 1 ? styles.selected : ""
          }
          ${
            type === "quiz"
              ? answers[start].isCorrect
                ? styles.correct
                : answers[start].userAnswer !== ""
                ? styles.wrong
                : ""
              : ""
          }`}
          onClick={() => setQuestionIndex(start + 1)}
        >
          {start + 1}
        </div>
      )}
      {start + 2 <= numberOfPage && (
        <div
          className={`${styles.circle} ${
            indexOfQuestion === start + 2 ? styles.selected : ""
          }
          ${
            type === "quiz"
              ? answers[start + 1].isCorrect
                ? styles.correct
                : answers[start + 1].userAnswer !== ""
                ? styles.wrong
                : ""
              : ""
          }`}
          onClick={() => setQuestionIndex(start + 2)}
        >
          {start + 2}
        </div>
      )}
      {start + 3 <= numberOfPage && (
        <div
          className={`${styles.circle} ${
            indexOfQuestion === start + 3 ? styles.selected : ""
          }
          ${
            type === "quiz"
              ? answers[start + 2].isCorrect
                ? styles.correct
                : answers[start + 2].userAnswer !== ""
                ? styles.wrong
                : ""
              : ""
          }`}
          onClick={() => setQuestionIndex(start + 3)}
        >
          {start + 3}
        </div>
      )}
      {start + 4 <= numberOfPage && (
        <div
          className={`${styles.circle} ${
            indexOfQuestion === start + 4 ? styles.selected : ""
          }
          ${
            type === "quiz"
              ? answers[start + 3].isCorrect
                ? styles.correct
                : answers[start + 3].userAnswer !== ""
                ? styles.wrong
                : ""
              : ""
          }`}
          onClick={() => setQuestionIndex(start + 4)}
        >
          {start + 4}
        </div>
      )}
    </div>
  );
}
export default QuestionsPagination;
