import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../Input/Input";
import { BeatLoader } from "react-spinners";
import { useCheckoutPackage } from "../../hooks/packages/useCheckoutPackage";
import styles from "./CheckoutForm.module.css";
import { useParams } from "react-router-dom";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import { useGetPackage } from "../../hooks/packages/useGetPackage";
import Loader from "../Loader/Loader";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetCourse } from "../../hooks/courses/useGetCourse";
import { dateFormat, getDateAfterMonths } from "../../utils/helper";

function CheckoutForm() {
  const { user } = useGetUser();
  const { packageId, courseId } = useParams();
  const { package: itemPackage, isLoading: isLoadingPackage } =
    useGetPackage(packageId);
  const { course, isLoading: isLoadingCourse } = useGetCourse(courseId);
  const [price, setPrice] = useState("");
  const { checkoutPackage, isLoading } = useCheckoutPackage(
    user.phone.substr(0, 2) === "20"
  );

  const schema = yup.object({
    referralCode: yup.string(),
    discountWallet: yup.string(),
    promoCode: yup.string(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const WalletValue = watch("discountWallet");

  function onSubmit(formData) {
    checkoutPackage(
      {
        packageId: packageId,
        courseId: courseId,
        userId: user.id,
        referralCode:
          formData.referralCode === "" ? "null" : formData.referralCode,
        WalletValue:
          formData.discountWallet === "" ? 0 : +formData.discountWallet,
        isEgypt: user.phone.substr(0, 2) === "20",
        promoCode: formData.promoCode === "" ? null : formData.promoCode,
      },
      {
        onSuccess: (data) => {
          window.open(data.urlToBuy, "_self");
        },
        onError: () => {
          toast.error("Your wallet balance is less than the amount");
        },
      }
    );
  }

  useEffect(
    function () {
      setPrice(
        user.phone.substr(0, 2) === "20"
          ? itemPackage?.priceForEgypt === undefined
            ? 0
            : itemPackage?.priceForEgypt
          : itemPackage?.priceForNonEgypt === undefined
          ? 0
          : itemPackage?.priceForNonEgypt
      );
    },
    [itemPackage, isLoadingPackage, user]
  );

  useEffect(
    function () {
      const packagePrice =
        user.phone.substr(0, 2) === "20"
          ? itemPackage?.priceForEgypt
          : itemPackage?.priceForNonEgypt;

      if (
        isLoadingPackage ||
        itemPackage === undefined ||
        WalletValue === undefined
      )
        return;

      if (+WalletValue > packagePrice) {
        toast.error("Loyalty points  more than needed price");
        setValue("discountWallet", "");
        return;
      } else {
        setPrice(packagePrice - +WalletValue);
      }
    },
    [WalletValue, itemPackage, user, isLoadingPackage, setValue]
  );

  if (isLoadingPackage || itemPackage === undefined || isLoadingCourse)
    return <Loader />;

  console.log(itemPackage);
  console.log(course);

  return (
    <div className={`${styles.form_container} my-5`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.input_container}`}>
          <label htmlFor="referralCode">Referral Code</label>
          <Input
            type="text"
            palceholder="Enter Referral Code"
            name="referralCode"
            id="referralCode"
            register={register}
            error={errors.referralCode ? errors.referralCode : null}
          />
          {errors.referralCode && (
            <p className="error">{errors.referralCode.message}</p>
          )}
        </div>

        <div className={`${styles.input_container}`}>
          <label htmlFor="discountWallet">Loyalty points </label>
          <Input
            type="text"
            palceholder="Enter Loyalty points"
            name="discountWallet"
            id="discountWallet"
            register={register}
            error={errors.discountWallet ? errors.discountWallet : null}
          />
          {errors.discountWallet && (
            <p className="error">{errors.discountWallet.message}</p>
          )}
        </div>

        <div className={`${styles.input_container}`}>
          <label htmlFor="discountWallet">Promo Code </label>
          <Input
            type="text"
            palceholder="Enter Promo Code"
            name="promoCode"
            id="promoCode"
            register={register}
            error={errors.promoCode ? errors.promoCode : null}
          />
          {errors.promoCode && (
            <p className="error">{errors.promoCode.message}</p>
          )}
        </div>
        <div>
          <div className={`${styles.price}`}>
            <span>Price :</span>{" "}
            <span>
              {user.phone.substr(0, 2) === "20" ? `${price} EGP` : `${price} $`}
            </span>
          </div>
        </div>
        <button
          type="submit"
          className={`${styles.submit_button}`}
          disabled={isLoading}
        >
          {isLoading ? <BeatLoader color="white" size={10} /> : "Checkout"}
        </button>
      </form>
      <div className={`${styles.description}`}>
        <div className={`${styles.itemDescription}`}>
          <p>Course Name :</p>
          <p>{course.name}</p>
        </div>
        <div className={`${styles.itemDescription}`}>
          <p>Subscription Period : </p>
          <p>{itemPackage.durationByMonths} Month</p>
        </div>
        <div className={`${styles.itemDescription}`}>
          <p>Start Date : </p>
          <p>{dateFormat(new Date())}</p>
        </div>
        <div className={`${styles.itemDescription}`}>
          <p>End Date : </p>
          <p>{getDateAfterMonths(itemPackage.durationByMonths)}</p>
        </div>
      </div>
    </div>
  );
}
export default CheckoutForm;
