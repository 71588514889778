import { useParams } from "react-router-dom";
import Question from "../Question/Question";
import styles from "./QuizAnswerContainer.module.css";
import { useGetExamAnswer } from "../../hooks/quiz/useGetExamAnswer";
import Loader from "../Loader/Loader";
import { useGetUser } from "../../hooks/Auth/useGetUser";

function QuizAnswerContainer() {
  const { id, examId } = useParams();
  const { user } = useGetUser();
  const { examAnswer, isLoading: isLoadingAnswer } = useGetExamAnswer(
    id,
    user.id,
    "finalExam",
    undefined,
    examId
  );

  if (isLoadingAnswer) return <Loader />;

  const grade = examAnswer?.grade;
  const finalGrade = examAnswer?.finalQuiz.length;
  const passGrade = Math.ceil(finalGrade / 2);

  return (
    <div className={`${styles.question_container} my-5`}>
      <div className={`${styles.grade_container}`}>
        <span
          className={
            passGrade <= grade
              ? grade === finalGrade
                ? styles.prefect_grade
                : styles.high_grade
              : styles.low_grade
          }
        >
          {grade}
        </span>{" "}
        / {finalGrade}
      </div>
      {examAnswer?.finalQuiz?.map((question, index) => (
        <Question
          type="answer"
          question={question}
          questionIndex={index}
          key={question.questionId}
        />
      ))}
    </div>
  );
}

export default QuizAnswerContainer;
