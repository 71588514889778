import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import { getFinalExamAnswer, getLessonQuizAnswer } from "../../services/quiz";

export function useGetExamAnswer(id, userId, type, level, examId) {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["examAnswer", id],
    queryFn: () =>
      type === "quiz"
        ? getLessonQuizAnswer(token, userId, id, level)
        : getFinalExamAnswer(token, userId, id, examId),
  });

  return { examAnswer: data, isLoading };
}
