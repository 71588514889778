import { useMutation } from "@tanstack/react-query";
import { attendFinalExam } from "../../services/courses";
import { useApp } from "../../context/AppProvider";

export function useAttendFinalExam() {
  const { token } = useApp();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ courseId, userId, examIdx }) =>
      attendFinalExam(token, courseId, userId, examIdx),
  });
  return { attendExam: mutate, isLoading: isPending };
}
