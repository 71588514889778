import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function setFinalExamAnswer(token, answers, examIdx) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/course/complete?idx=${examIdx}`,
      answers,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function setLessonQiuzQuestionAnswer(
  token,
  answer,
  userId,
  lessonId,
  level
) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/lesson/question/complete?userId=${userId}&lessonId=${lessonId}&level=${level}`,
      answer,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getFinalExamAnswer(token, userId, courseId, examIdx) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/course/answers?userId=${userId}&courseId=${courseId}&idx=${examIdx}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;

    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getLessonQuizAnswer(token, userId, lessonId, level) {
  if (level === undefined) return null;
  try {
    const response = await axios.get(
      `${baseUrl}/api/lesson/answers?userId=${userId}&lessonId=${lessonId}&level=${level}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function resetLessonQiuzQuestionAnswer(
  token,
  userId,
  lessonId,
  questionId,
  level
) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/lesson/question/reset?userId=${userId}&lessonId=${lessonId}&questionId=${questionId}&level=${level}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function resetFinalExam(token, userId, courseId, examIdx) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/course/reset?userId=${userId}&courseId=${courseId}&idx=${examIdx}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}
