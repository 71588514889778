import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function getChapters(token, courseId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/chapter/course/${courseId}?courseId=${courseId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}
