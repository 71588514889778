import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import blog1 from "../../assets/images/blog1.png"
import blog2 from "../../assets/images/blog2.png"
import blog3 from "../../assets/images/blog3.png"
import "./CourseBlog.css"
function CourseBlog() {
    return (
        <section className={`px-3 py-5 CoursesContainer my-5`}>
            <Container fluid>
                <Row>
                    <Col md={4}>
                        <div className='d-flex flex-column justify-content-center align-items-center item_container'>
                            <div className='blog_img_container'>
                                <img src={blog1} alt='blog1' />
                            </div>
                            <h4 className='font-weight-bold'>Accessible</h4>
                            <p>Easily study from your phone, tablet, or computer, allowing you to practice whenever and wherever it suits your schedule. A data connection is all it takes to get started.
                            </p>
                        </div>
                    </Col>
                    <Col md={4}>
                    <div className='d-flex flex-column justify-content-center align-items-center item_container'>
                            <div className='blog_img_container'>
                                <img src={blog2} alt='blog2' />
                            </div>
                            <h4 className='font-weight-bold'>Efficient</h4>
                            <p>
                            Our study materials, created and collected by our math experts, are structured for easy comprehension. With our extensive library of practice questions and full-length exams, you can feel assured and well-prepared.

                            </p>
                        </div>
                    </Col>
                    <Col md={4}>
                    <div className='d-flex flex-column justify-content-center align-items-center item_container'>
                            <div className='blog_img_container'>
                                <img src={blog3} alt='blog3' />
                            </div>
                            <h4 className='font-weight-bold'>Affordable</h4>
                            <p>You don’t need expensive classes and private tutors. We recognize budget constraints and have diligently kept costs low while ensuring a high-quality product with unique extras. There are no hidden fees or additional charges; everything is included.

                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CourseBlog