import styles from "./EditProfileForm.module.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../Input/Input";
import { useApp } from "../../context/AppProvider";
import { useEditProfile } from "../../hooks/Auth/useEditProfile";
import { BeatLoader } from "react-spinners";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";

function EditProfileForm() {
  const { token } = useApp();
  const { user, isLoading: isGetUser } = useGetUser();

  const schema = yup.object({
    first_name: yup
      .string()
      .required("Please enter your username")
      .min(3, "Username Should Be At Least 3 Characters")
      .max(15, "Username Should Be At Most 15 Characters"),
    last_name: yup
      .string()
      .required("Please enter your username")
      .min(3, "Username Should Be At Least 3 Characters")
      .max(15, "Username Should Be At Most 15 Characters"),
    city: yup.string().required("Please enter your city"),
    country: yup.string().required("Please enter your country"),
    school: yup.string().required("Please enter your school"),
    gender: yup.string().required("Please enter your gender"),
  });

  const { editProfile, isLoading } = useEditProfile();

  function onSubmit(data) {
    editProfile({ userId: user.id, editUser: data, token });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(schema) });
  const phoneValue = watch("phone");
  const parentPhoneValue = watch("parentPhone");

  useEffect(
    function () {
      register("phone");
      register("parentPhone");
      setValue("phone", user.phone);
      setValue("parentPhone", user.parentPhone);
    },
    [register, setValue, user]
  );

  if (isGetUser) return <Loader type="mini" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="nameInput">
        <div
          className={`input_container ${errors.first_name ? "error_container" : ""}`}
        >
          <label htmlFor="first_name">First Name</label>
          <Input
            type="text"
            palceholder="Enter your first name"
            name="first_name"
            id="first_name"
            register={register}
            error={errors.first_name ? errors.first_name : null}
            value={user.first_name}
          />
          {errors.first_name && (
            <p className="error">{errors.first_name.message}</p>
          )}
        </div>
        <div
          className={`input_container ${errors.last_name ? "error_container" : ""}`}
        >
          <label htmlFor="last_name">Last Name</label>
          <Input
            type="text"
            palceholder="Enter your last name"
            name="last_name"
            id="last_name"
            register={register}
            error={errors.last_name ? errors.last_name : null}
            value={user.last_name}
          />
          {errors.last_name && (
            <p className="error">{errors.last_name.message}</p>
          )}
        </div>
      </div>

      <div
        className={`input_container ${errors.country ? "error_container" : ""}`}
      >
        <label htmlFor="country">Country</label>
        <Input
          type="text"
          palceholder="Enter your country"
          name="country"
          id="country"
          register={register}
          error={errors.country ? errors.country : null}
          value={user.country}
        />
        {errors.country && <p className="error">{errors.country.message}</p>}
      </div>

      <div
        className={`input_container ${errors.city ? "error_container" : ""}`}
      >
        <label htmlFor="city">City</label>
        <Input
          type="text"
          palceholder="Enter your city"
          name="city"
          id="city"
          register={register}
          error={errors.city ? errors.city : null}
          value={user.city}
        />
        {errors.city && <p className="error">{errors.city.message}</p>}
      </div>

      <div
        className={`input_container ${errors.school ? "error_container" : ""}`}
      >
        <label htmlFor="school">School</label>
        <Input
          type="text"
          palceholder="Enter your school"
          name="school"
          id="school"
          register={register}
          error={errors.school ? errors.school : null}
          value={user.school}
        />
        {errors.school && <p className="error">{errors.school.message}</p>}
      </div>

      <div
        className={`input_container ${errors.phone ? "error_container" : ""}`}
      >
        <label htmlFor="phone">Phone</label>
        <PhoneInput
          country={"eg"}
          onChange={(value) => {
            setValue("phone", value);
          }}
          value={phoneValue}
          containerClass="custom-phone-input"
          inputClass="custom-phone-input"
          dropdownClass="custom-phone-input"
        />
        {errors.phone && <p className="error">{errors.phone.message}</p>}
      </div>

      <div
        className={`input_container ${
          errors.parentPhone ? "error_container" : ""
        }`}
      >
        <label htmlFor="parentPhone">Parent Phone</label>
        <PhoneInput
          country={"eg"}
          onChange={(value) => {
            setValue("parentPhone", value);
          }}
          value={parentPhoneValue}
          containerClass="custom-phone-input"
          inputClass="custom-phone-input"
          dropdownClass="custom-phone-input"
        />
        {errors.parentPhone && (
          <p className="error">{errors.parentPhone.message}</p>
        )}
      </div>

      <button
        type="submit"
        className={`${styles.submit_button}`}
        disabled={isLoading}
      >
        {isLoading ? <BeatLoader color="white" size={10} /> : "Edit Profile"}
      </button>
    </form>
  );
}
export default EditProfileForm;
