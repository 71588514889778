import ProfileCourseCard from "../ProfileCourseCard/ProfileCourseCard";
import styles from "./ProfileCourses.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useGetUserCourses } from "../../hooks/profile/useGetUserCourses";
import Loader from "../Loader/Loader";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import { useNavigate } from "react-router-dom";

function ProfileCourses() {
  const { user } = useGetUser();
  const { userCourses, isLoading } = useGetUserCourses(user.id);
  const navigate = useNavigate();

  if (isLoading) return <Loader />;

  return (
    <div className={`${styles.profile_courses_container}`}>
      <div className={`${styles.header}`}>
        <p>Subscriptions</p>
      </div>
      {userCourses.length === 0 ? (
        <div className={`${styles.empty_list}`}>
          <p>You didn't join to any courses untill now !</p>
          <button onClick={() => navigate("/courses")}>
            Go To Explore Courses
          </button>
        </div>
      ) : (
        <div className={`${styles.courses_container}`}>
          <Container fluid>
            {userCourses?.map((course) => (
                <Row key={course.courseId}>
                  <Col>
                    <ProfileCourseCard
                      course={course}
                      id={course.courseId}
                      img={course.courseImage}
                    />
                  </Col>
                </Row>
            ))}
          </Container>
        </div>
      )}
    </div>
  );
}
export default ProfileCourses;
