import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function getPackages(token) {
  try {
    const response = await axios.get(`${baseUrl}/packages`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getPackage(token, packageId) {
  try {
    const response = await axios.get(`${baseUrl}/packages/${packageId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function packageCheckOut(
  token,
  packageId,
  courseId,
  userId,
  referralCode,
  WalletValue,
  isEgypt,
  promoCode
) {
  try {
    const response = await axios.get(
      `${baseUrl}/create-payment-intent?pkg_id=${packageId}&course_id=${courseId}&user_id=${userId}&referralCode=${referralCode}&discount_wallet=${WalletValue}&IsEgypt=${isEgypt}&promoCode=${promoCode}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function packageCheckOutFromOutEgypt(
  token,
  packageId,
  courseId,
  userId,
  referralCode,
  WalletValue,
  isEgypt,
  promoCode
) {
  try {
    const response = await axios.post(
      `${baseUrl}/payment/create?pkgId=${packageId}&courseId=${courseId}&userId=${userId}&referralCode=${referralCode}&discountWallet=${WalletValue}&IsEgypt=${isEgypt}&promoCode=${promoCode}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}
