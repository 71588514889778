import PackageCard from "../PackgesCards/PackageCard";
import styles from "./PackagesContainer.module.css";
import PackageLogo1 from "../../assets/images/package1Logo.png";
import PackageLogo2 from "../../assets/images/package2Logo.png";
import PackageLogo3 from "../../assets/images/package3Logo.png";
import { useGetPackages } from "../../hooks/packages/useGetPackages";
import Loader from "../Loader/Loader";
import { useGetUser } from "../../hooks/Auth/useGetUser";

function PackagesContainer() {
  const { packages, isLoading } = useGetPackages();
  const { user, isLoading: isGetingUser } = useGetUser();
  const images = [PackageLogo1, PackageLogo2, PackageLogo3];
  const colors = ["#9C4DF4", "#FF6652", "#FFCD33"];

  if (isLoading || isGetingUser) return <Loader />;

  return (
    <div className={`px-4 py-5 ${styles.packages_container}`}>
      <p className={`${styles.title}`}>
        We create a pricing package for all standard students
      </p>
      <div className={`${styles.card_container} py-5`}>
        {packages.map((item, idx) => (
          <PackageCard
            duration={item.durationByMonths}
            type={item.name}
            img={images[idx % 3]}
            btnType={"2"}
            price={
              user.phone.substr(0, 2) === "20"
                ? `${item.priceForEgypt} EGP`
                : `${item.priceForNonEgypt} $`
            }
            color={colors[idx % 3]}
            description={item.description}
            packageId={item.id}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
}

export default PackagesContainer;
