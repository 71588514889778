import styles from "./ProfileTap.module.css";
import { NavLink } from "react-router-dom";
import userIcon from "../../assets/images/userIcon.png";
import { useGetUser } from "../../hooks/Auth/useGetUser";

function ProfileTap() {
  const { user } = useGetUser();

  return (
    <div className={`${styles.profile_tap}`}>
      <NavLink to="/profile">
        <img
          src={user.image !== null && user.image !== "" ? user.image : userIcon}
          alt="user"
        />
      </NavLink>
      <NavLink to="/profile">
        <span className={`${styles.name}`}>{user?.name}</span>
      </NavLink>
    </div>
  );
}
export default ProfileTap;
