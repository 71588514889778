import passedIcon from "../../assets/images/passedIcon.png";
import failedIcon from "../../assets/images/failedIcon.png";
import pendingIcon from "../../assets/images/pendingIcon.png";
import styles from "./QuizesTable.module.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useGetUser } from "../../hooks/Auth/useGetUser";

function QuizesTableRow({ lesson }) {
  const navigate = useNavigate();
  const { user } = useGetUser();

  return (
    <tr>
      <td>{lesson.courseName}</td>
      <td>{lesson.chapterName}</td>
      <td className={styles.lessonName}>
        {lesson.lessonName}{" "}
        <span className={styles[lesson.level]}>({lesson.level})</span>
      </td>
      {/* <td>5-7-2024</td> */}
      <td>
        <div className={`${styles.statusContainer}`}>
          <div
            className={`${styles.statusItem}`}
            onClick={() => {
              let isEnrolled = false;
              user.courses.map((element) =>
                element.courseId === lesson.courseId
                  ? (isEnrolled = true)
                  : null
              );

              if (lesson.lessonQuestions.right === "0") {
                toast.error("Not Exist Right Question");
                return;
              } else if (!isEnrolled) {
                navigate(`/packages/${lesson.courseId}`);
                return;
              }

              navigate(
                `/courses/course/lesson/quiz/${lesson.courseId}/${lesson.lessonId}/right/${lesson.level}`
              );
            }}
          >
            <img src={passedIcon} alt="passed" />
            <span>{lesson.lessonQuestions.right}</span>
          </div>
          <div
            className={`${styles.statusItem}`}
            onClick={() => {
              let isEnrolled = false;
              user.courses.map((element) =>
                element.courseId === lesson.courseId
                  ? (isEnrolled = true)
                  : null
              );

              if (lesson.lessonQuestions.wrong === "0") {
                toast.error("Not Exist Wrong Question");
                return;
              } else if (!isEnrolled) {
                navigate(`/packages/${lesson.courseId}`);
                return;
              }
              navigate(
                `/courses/course/lesson/quiz/${lesson.courseId}/${lesson.lessonId}/wrong/${lesson.level}`
              );
            }}
          >
            <img src={failedIcon} alt="failed" />
            <span>{lesson.lessonQuestions.wrong}</span>
          </div>
          <div
            className={`${styles.statusItem}`}
            onClick={() => {
              let isEnrolled = false;
              user.courses.map((element) =>
                element.courseId === lesson.courseId
                  ? (isEnrolled = true)
                  : null
              );

              if (lesson.lessonQuestions.notSolved === "0") {
                toast.error("Not Exist Not Solved Question");
                return;
              } else if (!isEnrolled) {
                navigate(`/packages/${lesson.courseId}`);
                return;
              }

              navigate(
                `/courses/course/lesson/quiz/${lesson.courseId}/${lesson.lessonId}/notSolved/${lesson.level}`
              );
            }}
          >
            <img src={pendingIcon} alt="pending" />
            <span>{lesson.lessonQuestions.notSolved}</span>
          </div>
        </div>
      </td>
    </tr>
  );
}
export default QuizesTableRow;
