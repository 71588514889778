import { useMutation } from "@tanstack/react-query";
import { signUp } from "../../services/Auth";
import toast from "react-hot-toast";

export function useSignup() {
  const { mutate, isPending } = useMutation({
    mutationFn: (user) => signUp(user),
    onSuccess: () =>
      toast.success(
        "Register succesfully, Please verify your account from your mail"
      ),
    onError: (error) => toast.error(error.message),
  });

  return { signup: mutate, isLoading: isPending };
}
