import { useMutation } from "@tanstack/react-query";
import { forgetPassword } from "../../services/Auth";
import toast from "react-hot-toast";

export function useForgetPassword() {
  const { mutate, isPending } = useMutation({
    mutationFn: (email) => forgetPassword(email),
    onSuccess: () => {
      toast.success("Check your email to get reset password code");
    },
    onError: (error) => toast.error(error.message),
  });

  return { forgetPassword: mutate, isLoading: isPending };
}
