import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function getUserCourses(token, userId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/courses/paid?userId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getAttendedLessonQuiz(token, userId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/lessons/info?userId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getAttendedFinalExams(token, userId) {
  try {
    const response = await axios.get(
      `${baseUrl}/api/courses/info?userId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);

    throw new Error(error.response.data);
  }
}
