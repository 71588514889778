import { Toaster } from "react-hot-toast";
import "./App.css";
import RoutesPage from "./RoutesPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProvider } from "./context/AppProvider";
import ScrollToTop from "./UI/ScrollToTop";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
      },
    },
  });

  return (
    <div className="app">
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop>
            <RoutesPage />
          </ScrollToTop>
        </QueryClientProvider>
      </AppProvider>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default App;
