import { useMutation } from "@tanstack/react-query";
import { setFinalExamAnswer } from "../../services/quiz";
import { useApp } from "../../context/AppProvider";

export function useSubmitFinalExamAnswer() {
  const { token } = useApp();
  const { mutate, isPending } = useMutation({
    mutationFn: ({answers , examIdx}) => setFinalExamAnswer(token , answers , examIdx),
  });

  return { submitAnswer: mutate, isLoading: isPending };
}
