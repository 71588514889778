import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function login(user) {
  try {
    const response = await axios.post(`${baseUrl}/api/signin`, user, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.data;
    return data;
  } catch (error) {
    if (error.response.status === 500)
      throw new Error("This Account not Registered or Not verified");
    throw new Error(error.response.data);
  }
}

export async function signUp(user) {
  try {
    const response = await axios.post(`${baseUrl}/api/signup`, user, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function forgetPassword(email) {
  try {
    const response = await axios.post(
      `${baseUrl}/api/forgotpassword?email=${email}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function resetPassword(userEmail, OTP, newPassword) {
  try {
    const response = await axios.put(
      `${baseUrl}/api/resetpassword?userEmail=${userEmail}&otp=${OTP}&newPassword=${newPassword}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function editProfile(userId, editUser, token) {
  try {
    const response = await axios.put(
      `${baseUrl}/api/profile?user_id=${userId}`,
      editUser,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function editPassword(password, token) {
  try {
    const response = await axios.put(`${baseUrl}/api/password`, password, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function getUser(token) {
  if (token === null || token === undefined) {
    return null;
  }
  try {
    const response = await axios.get(`${baseUrl}/api/profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    localStorage.setItem("token", null);
    window.location.reload();
    throw new Error(error.response.data);
  }
}

export async function editProfilePhoto(userId, editImage, token) {
  try {
    const response = await axios.put(
      `${baseUrl}/api/profile?user_id=${userId}`,
      editImage,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}
