import axios from "axios";
import { baseUrl } from "../utils/constent";

export async function getPagesContent(token) {
  try {
    const response = await axios.get(`${baseUrl}/api/pages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data[0];
  } catch (error) {
    throw new Error(error.response.data);
  }
}
