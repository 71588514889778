import toast from "react-hot-toast";
import { useApp } from "../../context/AppProvider";
import { useNavigate } from "react-router-dom";

export function useLogout() {
  const { setToken } = useApp();
  const navigate = useNavigate();
  const logout = () => {
    setToken(null);
    toast.success("Logout");
    navigate("/");
  };

  return { logout };
}
