import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Courses from "./Pages/Courses";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Packages from "./Pages/Packages";
import Aboutus from "./Pages/Aboutus";
import Contactus from "./Pages/Contactus";
import AppLayout from "./Pages/AppLayout/AppLayout";
import Course from "./Pages/Course";
import Profile from "./Pages/Profile";
import ProfileInfo from "./Components/ProfileInfo/ProfileInfo";
import ProfileCourses from "./Components/ProfileCourses/ProfileCourses";
import ProfileQuizes from "./Components/ProfileQuizes/ProfileQuizes";
import ProfileSetting from "./Components/ProfileSetting/ProfileSetting";
import EditProfileForm from "./Components/EditProfileForm/EditProfileForm";
import EditPasswordForm from "./Components/EditPasswordForm/EditPasswordForm";
import ProfileWallet from "./Components/ProfileWallet/ProfileWallet";
import ForgetPasswordForm from "./Components/AuthForm/ForgetPasswordForm";
import ResetPasswordForm from "./Components/AuthForm/ResetPasswordForm";
import Quiz from "./Pages/Quiz";
import QuizAnswer from "./Pages/QuizAnswer";
import QuizesTable from "./Components/QuizesTable/QuizesTable";
import FinalExamsTable from "./Components/FinalExamsTable/FinalExamsTable";
import CheckOut from "./Pages/CheckOut";
import ProtectRouter from "./Components/ProtectRoutes/ProtectRouter";
import Terms from "./Pages/Terms";
import FAQ from "./Pages/FAQ";
import Privacy from "./Pages/Privacy";

function RoutesPage() {
  return (
    <ProtectRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/page/:page" element={<Courses />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgetPassword" element={<ForgetPasswordForm />} />
          <Route path="/resetPassword/:email" element={<ResetPasswordForm />} />
          <Route path="/profile" element={<Profile />}>
            <Route path="/profile" element={<ProfileInfo />} />
            <Route path="/profile/courses" element={<ProfileCourses />} />
            <Route path="/profile/quizes" element={<ProfileQuizes />}>
              <Route path="/profile/quizes" element={<QuizesTable />} />
              <Route
                path="/profile/quizes/finalExams"
                element={<FinalExamsTable />}
              />
            </Route>
            <Route path="/profile/setting" element={<ProfileSetting />}>
              <Route path="/profile/setting" element={<EditProfileForm />} />
              <Route
                path="/profile/setting/editPassword"
                element={<EditPasswordForm />}
              />
            </Route>
            <Route path="/profile/wallet" element={<ProfileWallet />} />
          </Route>
          <Route path="/packages" element={<Packages />} />
          <Route path="/packages/:courseId" element={<Packages />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy" element={<Privacy />} />

          <Route path="/terms" element={<Terms />} />
          <Route path="/courses/course/:courseId" element={<Course />} />
          <Route
            path="/courses/course/lesson/quiz/:courseId/:lessonId/:level"
            element={<Quiz />}
          />
          <Route
            path="/courses/course/lesson/quiz/:courseId/:lessonId/:status/:level"
            element={<Quiz />}
          />
          <Route
            path="/courses/course/lesson/quizFreeTrier/:courseId/:lessonId"
            element={<Quiz />}
          />
          <Route
            path="/courses/course/exam/:courseId/:examIdx"
            element={<Quiz />}
          />
          <Route
            path="/courses/course/exam/:time/:courseId/:examIdx"
            element={<Quiz />}
          />
          <Route path="/" />
          <Route path="/examAnswer/:id/:examId" element={<QuizAnswer />} />
          <Route path="/checkout/:courseId/:packageId" element={<CheckOut />} />
        </Route>
      </Routes>
    </ProtectRouter>
  );
}

export default RoutesPage;
