import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editProfile } from "../../services/Auth";
import toast from "react-hot-toast";

export function useEditProfile() {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ userId, editUser, token }) =>
      editProfile(userId, editUser, token),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      toast.success("Edit profile successfully");
    },
    onError: (error) => toast.error(error.message),
  });

  return { editProfile: mutate, isLoading: isPending };
}
