import React from "react";
import Input from "../Input/Input";
import "./AuthForm.css";
import logo from "../../assets/images/logo.jpg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useForgetPassword } from "../../hooks/Auth/useForgetPassword";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function ForgetPasswordForm() {
  const schema = yup.object({
    email: yup
      .string()
      .required("Please enter your email")
      .email("Invalid Email Format"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { forgetPassword, isLoading } = useForgetPassword();
  const navigate = useNavigate();

  function onSubmit(data) {
    forgetPassword(data.email, {
      onSuccess: () => {
        navigate(`/resetPassword/${data.email}`);
      },
    });
  }

  return (
    <div className="AuthForm_container py-5">
      <div className="form_container py-5">
        <div className="logo_container">
          <img src={logo} alt="logo" />
          <p className="title">Forget Password</p>
          <p>
            Lost your password? Please enter your email address. <br />
            You will receive a link to create a new password via email.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input_container">
            <label htmlFor="email">Email</label>
            <Input
              type="text"
              palceholder="Enter your email"
              name="email"
              id="email"
              register={register}
              error={errors.email ? errors.email : null}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>

          <button type="submit" className="submit_button" disabled={isLoading}>
            {isLoading ? (
              <BeatLoader color="white" size={10} />
            ) : (
              "Reset Password "
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgetPasswordForm;
