import { Outlet } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import Loader from "../../Components/Loader/Loader";
import styles from "./AppLayout.module.css";
import { FaArrowUp } from "react-icons/fa";
import { scrollToTop } from "../../utils/helper";
import { useEffect, useState } from "react";

function AppLayout() {
  const { isLoading } = useGetUser();
  const [show, setShow] = useState(false);

  useEffect(function () {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShow(scrollPosition > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      {show && (
        <button className={styles.toTopBtn} onClick={() => scrollToTop()}>
          <FaArrowUp />
        </button>
      )}
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
export default AppLayout;
