import { useQuery } from "@tanstack/react-query";
import { getUserCourses } from "../../services/profile";
import { useApp } from "../../context/AppProvider";

export function useGetUserCourses(userId) {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["userCourses", userId],
    queryFn: () => getUserCourses(token, userId),
  });

  return { userCourses: data, isLoading };
}
