import { useEffect } from "react";
import styles from "./Timer.module.css";

function Timer({ time, setTime }) {
  useEffect(
    function () {
      if (time === 0) return;
      const id = setInterval(function () {
        setTime((e) => e - 1);
      }, 1000);

      return () => clearInterval(id);
    },
    [time, setTime]
  );
  return (
    <div
      className={`${styles.timer_container} ${
        time === 0 ? styles.time_end : ""
      }`}
    >
      <span>
        {Math.floor(time / 60) < 10 ? "0" : ""}
        {Math.floor(time / 60)}
      </span>{" "}
      :{" "}
      <span>
        {time % 60 < 10 ? "0" : ""}
        {time % 60}
      </span>
    </div>
  );
}
export default Timer;
