import { Link } from "react-router-dom";
import styles from "./ProfileCourseCard.module.css";
import { dateFormat } from "../../utils/helper";

function ProfileCourseCard({ img, course, id }) {
  return (
    <div className={`${styles.CourseCard_container}`}>
      <div className={`${styles.img_container}`}>
        <img alt="img" src={img} />
      </div>
      <div className={styles.discription}>
        <div>
          <div className={`${styles.descriptionItem}`}>
            <p>Course Name :</p>
            <p>{course.courseName}</p>
          </div>
          <div className={styles.descriptionItem}>
            <p>Duration :</p>
            <p>{course.duration} Month</p>
          </div>
          <div className={styles.descriptionItem}>
            <p>Start :</p>
            <p>{dateFormat(course.startDate)}</p>
          </div>
          <div className={styles.descriptionItem}>
            <p>End :</p>
            <p>{dateFormat(course.expiryDate)}</p>
          </div>
        </div>
        <div
          className={`d-flex justify-content-end px-3 pb-3 ${styles.btn_container}`}
        >
          <Link
            to={`/courses/course/${id}`}
            className={`${styles.seemore_link}`}
          >
            <div>Start Learning</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProfileCourseCard;
