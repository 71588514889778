import styles from "./AboutusContainer.module.css";
import { useGetPagesContent } from "../../hooks/pages/useGetPagesContent";
import Loader from "../Loader/Loader";
import aboutImg from "../../assets/images/Untitled.png"
function AboutusContainer() {
  const { pagesContent, isLoading } = useGetPagesContent();
  if (isLoading) return <Loader />;
  return (
    <div className={`m-5 ${styles.aboutContainer}`}>
      <div className={styles.contentContainer}>
        <div className={styles.title}>About Us</div>
        <div className={styles.content}>
          <h4>Mathapes: Keep climbing </h4>
          <p>Elevate your math skills with Mathapes, your personal guide to conquering mathematics. We're dedicated to providing students of all levels with the tools and resources they need to excel in this essential subject.</p>
          <p>Our comprehensive question banks cover a wide range of math topics, ensuring you're well-prepared for any challenge. Practice exams that simulate real-test conditions help you build confidence and exam readiness. With personalized learning, Mathapes tailors questions based on your individual needs and progress.
          </p>
          <p>Enjoy access to high-quality math resources without breaking the bank. Our affordable subscription makes Mathapes accessible to students of all backgrounds.
          </p>

          <h4>Why Choose Mathapes? </h4>
          <p>Benefit from our team of experienced educators who are passionate about helping you succeed. See firsthand how Mathapes has helped countless students achieve their math goals. We're dedicated to providing you with the best possible learning experience.
          </p>
          <h4>Join the Mathapes Community Today
          </h4>
          <p>Ready to take your math skills to the next level? 
          </p>
          <p>Join Mathapes and discover the power of personalized learning.
          </p>
          <img src={aboutImg}alt="Untitled" className="w-100"/>
        </div>
      </div>
    </div>
  );
}
export default AboutusContainer;
