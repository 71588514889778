import React from "react";
import styles from "./PageHeader.module.css";
import { Link } from "react-router-dom";
import profileHeader from "../../assets/images/profileHeaderImg.jpg";
function PageHeader({ title, from = "any", subTitle }) {
  return (
    <section
      className={`py-5 my-5 ${styles.section_container} d-flex justify-content-between`}
    >
      <div>
        <div className="d-flex">
          <div className={`d-flex align-items-center bg-white px-3 py-2 mb-4 rounded ${styles.title_link}`}>
            <Link to="/" className="text-dark">
              {" "}
              Home{" "}
            </Link>
            <span className="mx-1">{">"} </span>
            <p className="mb-0">{title}</p>
          </div>
        </div>
        <p className={`font-weight-bold ${styles.title}`}>
          {from === "course" ? subTitle : title}
        </p>
      </div>
      <div style={{ height: "150px" }}>
        <img src={profileHeader} alt="profileHeader" className="h-100" />
      </div>
    </section>
  );
}

export default PageHeader;
