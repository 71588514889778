import { useQuery } from "@tanstack/react-query";
import { getCourse } from "../../services/courses";
import { useApp } from "../../context/AppProvider";

export function useGetCourse(courseId) {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["course", courseId],
    queryFn: () => getCourse(token, courseId),
  });

  return { course: data, isLoading };
}
