import React from "react";
import MainHeader from "../Components/MainHeader/MainHeader";
import CoursesContainer from "../Components/CoursesContainer/CoursesContainer";
import imgC from "../assets/images/course1.jpg";
import CourseBlog from "../Components/CourseBlog/CourseBlog";

function Home() {
  const items = [
    { id: 1, img: imgC, courseName: "course 1" },
    { id: 2, img: imgC, courseName: "course 1" },
    { id: 3, img: imgC, courseName: "course 1" },
    { id: 4, img: imgC, courseName: "course 1" },
    { id: 5, img: imgC, courseName: "course 1" },
    { id: 6, img: imgC, courseName: "course 1" },
  ];

  return (
    <div>
      <MainHeader />
      <CoursesContainer items={items} />
      <CourseBlog />
    </div>
  );
}

export default Home;
