import { useNavigate, useParams } from "react-router-dom";
// import Correct from "../../assets/images/correctIcon.png";
import styles from "./PackageCard.module.css";

function PackageCard({
  duration,
  type,
  img,
  btnType,
  price,
  color,
  description,
  packageId,
}) {
  const { courseId } = useParams();
  const navigate = useNavigate();

  return (
    <div className={`${styles.package_card}`}>
      <div>
        <div className={styles.title_container}>
          <div className={styles.title}>
            <img src={img} alt="logo" />
            <p>{duration} Months</p>
          </div>
          <div className={styles.type} style={{ color }}>
            {type}
          </div>
        </div>
        <div className={`${styles.details_container}`}>
          <ul>
            {/* {description.map((item, idx) => (
              <li key={idx}>
                <img src={Correct} alt="correct" />
                <p>{item}</p>
              </li>
            ))} */}
            <li className="justify-content-center font-weight-bold align-items-center">
              600 EGP <span className="font-weight-normal">per Month</span>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.price_container}>
        <p>{price}</p>
        <button
          className={`${btnType === "1" ? styles.btn1 : styles.btn2}`}
          onClick={() => {
            navigate(`/checkout/${courseId}/${packageId}`);
          }}
        >
          Subscribe
        </button>
      </div>
    </div>
  );
}
export default PackageCard;
