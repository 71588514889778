import PageHeader from "../Components/PageHeader/PageHeader";
import QuizAnswerContainer from "../Components/QuizAnswerContainer/QuizAnswerContainer";

function QuizAnswer() {
  return (
    <div>
        <PageHeader 
        title="Courses > Course > Quiz > Answer"
        subTitle="Quiz Answer"
        from="course"
        />
        <QuizAnswerContainer/>
    </div>
  );
}
export default QuizAnswer;
