import { Link, Outlet, useLocation } from "react-router-dom";
import styles from "./ProfileSetting.module.css";

function ProfileSetting() {
  let currentPage = useLocation();
  currentPage = currentPage.pathname;

  return (
    <div className={`${styles.profile_setting_container}`}>
      <div className={`${styles.header}`}>
        <p>
          {currentPage==="/profile/setting" ? "Edit Profile" : "Edit Password"}
        </p>
      </div>

      <div className={`${styles.btns}`}>
        <Link
          to="/profile/setting"
          className={`${
            currentPage === "/profile/setting" ? styles.active_link : ""
          }`}
        >
          Profile
        </Link>
        <Link
          to="/profile/setting/editPassword"
          className={`${
            currentPage === "/profile/setting/editPassword"
              ? styles.active_link
              : ""
          }`}
        >
          Password
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
export default ProfileSetting;
