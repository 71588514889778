import React from "react";
import styles from "./Input.module.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";
function Input({
  palceholder,
  type,
  name,
  id,
  register,
  error,
  show,
  setShow,
  value = "",
}) {
  return (
    <div
      className={`${styles.input_container} ${
        error !== null ? styles.error : ""
      }`}
    >
      <input
        type={type === "password" ? (show ? "text" : "password") : type}
        placeholder={palceholder}
        name={name}
        id={id}
        {...register(name)}
        autoComplete="false"
        defaultValue={value}
      />
      {type === "password" ? (
        <span onClick={() => setShow((e) => !e)}>
          {!show ? <BsEye /> : <BsEyeSlash />}
        </span>
      ) : null}
    </div>
  );
}

export default Input;
