import React from "react";
import TermsContainer from "../Components/TermsContainer/TermsContainer";

function Terms() {
  return (
    <div>
      <TermsContainer />
    </div>
  );
}

export default Terms;
