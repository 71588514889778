import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resetFinalExam } from "../../services/quiz";
import { useApp } from "../../context/AppProvider";

export function useResetFinalExam() {
  const { token } = useApp();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ userId, courseId, examIdx }) =>
      resetFinalExam(token, userId, courseId, examIdx),
    onSuccess: () => {
      queryClient.invalidateQueries(["examAnswer"]);
    },
  });

  return { resetFinalExam: mutate, isLoading: isPending };
}
