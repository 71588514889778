import userIcon from "../../assets/images/userIconWhite.png";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import styles from "./ProfileHeader.module.css";

function ProfileHeader() {
  const { user } = useGetUser();
  return (
    <div className={`${styles.profile_header} my-5`}>
      <img
        src={user.image !== null && user.image !== "" ? user.image : userIcon}
        alt="user"
      />
      <div className={`${styles.info}`}>
        <span>Hello</span>
        <p>{user?.name}</p>
      </div>
    </div>
  );
}
export default ProfileHeader;
