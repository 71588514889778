import { useQuery } from "@tanstack/react-query";
import { getCourses } from "../../services/courses";

export function useGetCourses(pageNum) {
  const { data, isLoading } = useQuery({
    queryKey: ["courses", pageNum],
    queryFn: () => getCourses(pageNum),
  });

  return { courses: data, isLoading };
}
