import { useEffect, useState } from "react";
import styles from "./ChapterTab.module.css";
import { useNavigate } from "react-router-dom";
import { useAttendFinalExam } from "../../hooks/courses/useAttendFinalExam";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import { BeatLoader } from "react-spinners";
import { useAttendLessonQuiz } from "../../hooks/courses/useAttendLessonQuiz";
import { useGetLessons } from "../../hooks/lesson/useGetLessons";
import Modal from "../../UI/Modal/Modal";

function ChapterTab({ title, type, chapter, courseId, time, examIdx }) {
  const [showQuizes, setShowQuizes] = useState(false);
  const { attendExam, isLoading: isFinalExamLoading } = useAttendFinalExam();
  const { attendLessonQuiz, isLoading: isLessonQuizLoading } =
    useAttendLessonQuiz();
  const [isCurrentAttendQuiz, setIsCurrentAttendQiz] = useState(null);
  const { lessons } = useGetLessons(chapter?.id);

  const { user } = useGetUser();
  const navigate = useNavigate();

  const [level, setLevel] = useState("easy");
  const [timer, setTimer] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);

  function handleAttendQuiz(type, lessonId) {
    // check user if enrolled course or not
    let isEnrolled = false;
    user.courses.map((element) =>
      element.courseId === courseId ? (isEnrolled = true) : null
    );

    // if (!isEnrolled) {
    //   navigate(`/packages/${courseId}`);
    //   return;
    // }

    if (type === "finalExam") {
      attendExam(
        { courseId: courseId, userId: user.id, examIdx: examIdx },
        {
          onSuccess: (data) => {
            if (!data) {
              if (timer) {
                navigate(`/courses/course/exam/${time}/${courseId}/${examIdx}`);
              } else {
                navigate(`/courses/course/exam/${courseId}/${examIdx}`);
              }
            } else {
              navigate(`/examAnswer/${courseId}/${examIdx}`);
            }
          },
        }
      );
    } else {
      setIsCurrentAttendQiz(lessonId);
      attendLessonQuiz(
        { lessonId: lessonId, userId: user.id, level: level },
        {
          onSuccess: () => {
            navigate(
              `/courses/course/lesson/quiz/${courseId}/${lessonId}/${level}`
            );
          },
          onSettled: () => setIsCurrentAttendQiz(null),
        }
      );
    }
  }

  useEffect(
    function () {
      let test = false;
      user.courses.map((element) =>
        element.courseId === courseId ? (test = true) : null
      );

      setIsEnrolled(test);
    },
    [courseId, user]
  );

  return (
    <div className={`${styles.lesson_container}`}>
      <div
        className={`${styles.lesson_tab}`}
        onClick={() => setShowQuizes((e) => !e)}
      >
        {type !== "exam" ? chapter.name : title}
        {type === "exam" && (
          <Modal>
            <Modal.Open>
              <button className={`${styles.exam_btn}`}>
                {isFinalExamLoading ? (
                  <BeatLoader color="black" size={8} />
                ) : (
                  "Start"
                )}
              </button>
            </Modal.Open>
            <Modal.Window>
              <div className={styles.modal_container}>
                <h4>How Would you prefer your exam to be ?</h4>
                <div className={styles.timer_form}>
                  <div className="mx-5">
                    <input
                      type="radio"
                      id="timer"
                      name="timer"
                      value={timer}
                      onChange={(e) => setTimer(true)}
                      checked={timer === true}
                    />
                    <label htmlFor="timer">Timed</label>
                  </div>
                  <div className="mx-5">
                    <input
                      type="radio"
                      id="untimer"
                      name="timer"
                      value={timer}
                      onChange={(e) => setTimer(false)}
                      checked={timer === false}
                    />
                    <label htmlFor="untimer">Untimed</label>
                  </div>
                </div>
                <button
                  onClick={() => handleAttendQuiz("finalExam")}
                  disabled={isFinalExamLoading}
                  className={`${styles.quiz_btn}`}
                >
                  {isFinalExamLoading ? (
                    <BeatLoader color="white" size={8} />
                  ) : (
                    "Start Exam"
                  )}
                </button>
              </div>
            </Modal.Window>
          </Modal>
        )}
      </div>

      {showQuizes &&
        type !== "exam" &&
        lessons?.map((lesson) => (
          <div key={lesson.id}>
            <div className={`${styles.quiz_tab}`}>
              <div className={styles.tapTitle}>
                <p>{lesson.name}</p>
                <p className={styles.subTitle}>
                  Total number of questions : <span>{lesson.allQuestions}</span>
                </p>
                <div className={styles.freeTrier}>
                  <p>
                    Free trier sample questions :{" "}
                    <span>{lesson.freeQuestions}</span>
                  </p>
                  {/* <button
                    onClick={() =>
                      navigate(
                        `/courses/course/lesson/quizFreeTrier/${courseId}/${lesson.id}`
                      )
                    }
                  >
                    Try it for free
                  </button> */}
                </div>
              </div>
              {isEnrolled ? (
                <Modal>
                  <Modal.Open>
                    <button className={`${styles.exam_btn}`}>Start</button>
                  </Modal.Open>
                  <Modal.Window>
                    <div className={styles.modal_container}>
                      <div className={styles.level_form}>
                        <p>Select Practice Level :</p>
                        <div className={styles.level_input}>
                          <input
                            type="radio"
                            value="easy"
                            id="easy"
                            name="level"
                            checked={level === "easy"}
                            onChange={(e) => setLevel(e.target.value)}
                          />
                          <label htmlFor="easy">Easy</label>
                        </div>
                        <div className={styles.level_input}>
                          <input
                            type="radio"
                            value="medium"
                            id="medium"
                            name="level"
                            checked={level === "medium"}
                            onChange={(e) => setLevel(e.target.value)}
                          />
                          <label htmlFor="medium">Medium</label>
                        </div>
                        <div className={styles.level_input}>
                          <input
                            type="radio"
                            value="hard"
                            id="hard"
                            name="level"
                            checked={level === "hard"}
                            onChange={(e) => setLevel(e.target.value)}
                          />
                          <label htmlFor="hard">Hard</label>
                        </div>
                      </div>
                      <button
                        onClick={() => handleAttendQuiz("quiz", lesson.id)}
                        disabled={isLessonQuizLoading}
                        className={`${styles.quiz_btn}`}
                      >
                        {isCurrentAttendQuiz === lesson.id ? (
                          <BeatLoader color="white" size={8} />
                        ) : (
                          "Start"
                        )}
                      </button>
                    </div>
                  </Modal.Window>
                </Modal>
              ) : (
                <button
                  className={`${styles.exam_btn}`}
                  onClick={() =>
                    navigate(
                      `/courses/course/lesson/quizFreeTrier/${courseId}/${lesson.id}`
                    )
                  }
                >
                  Start With Free Trier
                </button>
              )}{" "}
            </div>
          </div>
        ))}
    </div>
  );
}
export default ChapterTab;
