import React from "react";
import "./CourseCard.css";
import { Link } from "react-router-dom";
function CourseCard({ course }) {
  return (
    <div className="CourseCard_container">
      <div className="img_container h-75">
        <img alt="" src={course?.image} />
      </div>
      <h5 className="px-3 pt-3 pb-2">{course?.name}</h5>
      <div className="d-flex justify-content-end px-3 pb-3 btn_container">
        <div>
          <Link to={`/courses/course/${course?.id}`} className="seemore_link">
            see More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
