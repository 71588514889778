import { useParams } from "react-router-dom";
import styles from "./CourseContainer.module.css";
import { useGetCourse } from "../../hooks/courses/useGetCourse";
import Loader from "../Loader/Loader";
import PageHeader from "../PageHeader/PageHeader";
import PackagesContainer from "../PackagesContainer/PackagesContainer";
import { useGetChapters } from "../../hooks/chapters/useGetChapters";
import ChapterTab from "../ChapterTab/ChapterTab";

function CourseContainer() {
  const { courseId } = useParams();
  const { course, isLoading } = useGetCourse(courseId);
  const { chapters, isGetChapter } = useGetChapters(courseId);
  if (isLoading || isGetChapter) return <Loader />;

  return (
    <div>
      <PageHeader
        title="Courses > Course"
        from="course"
        subTitle={course?.name}
      />
      <div className={`${styles.course_container} mx-3 my-5`}>
        <div className={`${styles.overview_container} my-5`}>
          <p className={`${styles.title}`}>OVERVIEW</p>
          <p className={`${styles.description}`}>{course.description}</p>
        </div>
        <div className={`${styles.lesson_container} my-5`}>
          <p className={`${styles.title}`}>Content</p>

          <ul
            className="nav nav-tabs border-bottom-0 d-flex justify-content-center"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link  border-0  font-weight-bold"
                id="contact-tab"
                data-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Lessons
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link  border-0 active font-weight-bold"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Practice Tests
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {course.finalExams.map((item, idx) => (
                <ChapterTab
                  key={idx}
                  examIdx={idx}
                  title={`Practice Test ${idx + 1}`}
                  type="exam"
                  courseId={course.id}
                  time={course.timer}
                />
              ))}
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              {chapters?.map((chapter, index) => (
                <ChapterTab
                  chapter={chapter}
                  key={index}
                  courseId={course.id}
                  time={course.timer}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <PackagesContainer />
    </div>
  );
}
export default CourseContainer;
