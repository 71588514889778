import React from "react";
import PageHeader from "../Components/PageHeader/PageHeader";
import PackagesContainer from "../Components/PackagesContainer/PackagesContainer";
import imgC from "../assets/images/course1.jpg";

function Packages() {
  const items = [
    { id: 1, img: imgC, courseName: "course 1" },
    { id: 2, img: imgC, courseName: "course 1" },
    { id: 3, img: imgC, courseName: "course 1" },
    { id: 4, img: imgC, courseName: "course 1" },
    { id: 5, img: imgC, courseName: "course 1" },
    { id: 6, img: imgC, courseName: "course 1" },
    { id: 7, img: imgC, courseName: "course 1" },
    { id: 8, img: imgC, courseName: "course 1" },
    { id: 9, img: imgC, courseName: "course 1" },
  ];

  return (
    <div>
      <PageHeader title={"Packages"} />
      <PackagesContainer pagination={true} items={items} />
    </div>
  );
}

export default Packages;
