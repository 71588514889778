import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import {
  getLessonQuiz,
  getLessonQuizFreeQuestion,
} from "../../services/lesson";
import { getFinalExam } from "../../services/courses";

export function useGetExam(id, type, level , examIdx) {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["exam", id, type],
    queryFn: () =>
      type === "quiz"
        ? getLessonQuiz(token, id, level)
        : type === "quizFreeTrier"
        ? getLessonQuizFreeQuestion(token, id)
        : getFinalExam(token, id , examIdx),
  });

  return { exam: data, isLoading };
}
