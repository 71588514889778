import { Container } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import styles from "./Loader.module.css";

function Loader({ type = "full" }) {
  return (
    <Container
      fluid
      className={`vh-100 w-100 d-flex justify-content-center align-items-center ${
        type === "mini" ? "" : styles.container
      }`}
    >
      <FadeLoader color="#7d3c98" />
    </Container>
  );
}

export default Loader;
