import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../context/AppProvider";
import { getLessons } from "../../services/lesson";

export function useGetLessons(chapterId) {
  const { token } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: ["lessons", chapterId],
    queryFn: () => getLessons(token, chapterId),
  });

  return { lessons: data, isLoading };
}
