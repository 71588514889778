import React from 'react'
import "./MainHeader.css"
import { Col, Container, Row } from 'react-bootstrap'
import headerImg from "../../assets/images/headerImg.jpeg"
import { Link } from 'react-router-dom'

function MainHeader() {
  return (
    <section className='MainHeader_container py-5'>
      <Container>
        <Row>
          <Col md={8}>
            <div>
              <p className='font-weight-bold' style={{ fontSize: "28px" }}>Keep climbing  </p>
              <p className='font-weight-bold' style={{ fontSize: "30px" }}><span>Mathapes</span></p>
              <p className='font-weight-bold' style={{ fontSize: "30px" }}>Your One-Stop Test Prep Destination
              </p>
              <p className='header_paragraph my-4'>Comprehensive resources for every student.
              </p>
              {/* <div className='mt-5 header_btn d-flex'>
                <div>
                  <Link>
                    Find Our Courses
                  </Link>
                </div>
              </div> */}

            </div>
          </Col>
          <Col md={4}>
            <div>
              <img src={headerImg} alt='headerImg' className='w-100 mt-3' />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MainHeader
