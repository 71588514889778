import styles from "./Question.module.css";
import { MdLightbulbOutline } from "react-icons/md";
import { FaRepeat } from "react-icons/fa6";
import { useState } from "react";
import { useSubmitFinalExamAnswer } from "../../hooks/quiz/useSubmitFinalExamAnswer";
import { useGetUser } from "../../hooks/Auth/useGetUser";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useSubmitQuizQuestion } from "../../hooks/quiz/useSubmitQuizQuestion";
import { BeatLoader, ClipLoader } from "react-spinners";
import { useResetQuizQuestion } from "../../hooks/quiz/useResetQuizQuestion";
import calc from "../../assets/images/calc.png";
import noCalc from "../../assets/images/noCalc.png";

function Question({
  type,
  question,
  answers,
  setAnswers,
  questionIndex,
  id,
  examIdx,
}) {
  const { courseId, level } = useParams();
  const [answer, setAnswer] = useState(answers?.[questionIndex]?.userAnswer);
  const [test, setTest] = useState(false);
  const { submitAnswer: submitAnswerFinalExam, isLoading: isfinalExamLoading } =
    useSubmitFinalExamAnswer();
  const { submitAnswer: submitAnswerQuiz, isLoading: isQuizLoading } =
    useSubmitQuizQuestion();
  const isLoading = isfinalExamLoading || isQuizLoading;
  const { user } = useGetUser();
  const navigate = useNavigate();

  const [showExplain, setShowExplain] = useState(false);
  const { resetQuestion, isLoading: isLoadingResetQuestion } =
    useResetQuizQuestion();

  function handleSelectAnswer(answer) {
    setAnswer(answer);
    if (type === "quiz" || type === "freeQuiz") return;
    let newAnswer = answers;
    newAnswer[questionIndex] = {
      ...newAnswer[questionIndex],
      userAnswer: answer,
      isCorrect: answer === question.correctAnswer,
    };

    setAnswers(newAnswer);
  }

  function handleSubmitAnswer() {
    if (type === "quiz" || type === "freeQuiz") {
      if (answer === "") {
        toast.error("Please Choose Answer First");
        return;
      }
      let newAnswer = answers;
      newAnswer[questionIndex] = {
        ...newAnswer[questionIndex],
        userAnswer: answer,
        isCorrect: answer === question.correctAnswer,
      };
      if (type === "freeQuiz") {
        setAnswers([]);
        setTest(!test);
      }
      setAnswers(newAnswer);
      if (type === "freeQuiz") return;
    }

    const answersModel =
      type === "quiz"
        ? {
            questionId: question.id,
            isCorrect: answer === question.correctAnswer,
            question: question.question,
            correctAnswer: question.correctAnswer,
            userAnswer: answer,
            explaination: question.explanation,
            options: question.options,
          }
        : {
            userId: user.id,
            courseId: courseId,
            questionsAnswers: answers,
          };
    if (type === "quiz") {
      submitAnswerQuiz({
        answers: answersModel,
        userId: user.id,
        lessonId: id,
        level: level,
      });
    } else {
      submitAnswerFinalExam(
        { answers: answersModel, examIdx: examIdx },
        {
          onSuccess: (data) => {
            toast.success("Submit Practice Exam Answers Successfully");
            navigate(`/examAnswer/${courseId}/${examIdx}`);
          },
        }
      );
    }
  }

  function handleResetQuestion() {
    if (type === "freeQuizAnswered" || type === "freeQuiz") {
      const tmp = answers;
      tmp[questionIndex].userAnswer = "";
      setAnswers([]);
      setAnswers(tmp);
      setAnswer("");
      return;
    }

    resetQuestion(
      {
        userId: user.id,
        lessonId: id,
        questionId: question.questionId,
        level: level,
      },
      {
        onSuccess: () => {
          toast.success("Reseting Question successfully");
          setAnswer("");
        },
      }
    );
  }

  if (
    (type === "freeQuizAnswered" && answers[questionIndex].userAnswer !== "") ||
    (type === "freeQuiz" && answers[questionIndex].userAnswer !== "")
  ) {
    return (
      <div className={`${styles.question_container}`}>
        <div className={`${styles.btns}`}>
          <div className={styles.btnContainer}>
            <button
              className={`${styles.explain_btn}`}
              onClick={() => setShowExplain((e) => !e)}
            >
              <span>
                <MdLightbulbOutline />
              </span>{" "}
              <p>Explanation</p>
            </button>
            <button
              className={`${styles.calcBtn}`}
              onClick={() =>
                answers[questionIndex].calc
                  ? toast.success("Can Use Calculator In This Question")
                  : toast.error("Can't Use Calculator In This Question")
              }
            >
              <img src={question.calc ? calc : noCalc} alt="icon" />
            </button>
          </div>
          <button
            className={`${styles.repeat_btn}`}
            onClick={handleResetQuestion}
            disabled={isLoadingResetQuestion}
          >
            {isLoadingResetQuestion ? (
              <ClipLoader color="white" size={30} />
            ) : (
              <FaRepeat />
            )}
          </button>
        </div>
        <div className={`${styles.question}`}>
          {questionIndex + 1}- {answers[questionIndex]?.question}
        </div>
        <div className={styles.secodery_container}>
          {question?.questionImage !== null && question?.questionImage !== "" && (
            <img src={question?.questionImage} alt="img" />
          )}
          <form className={`${styles.answerForm}`}>
            {answers[questionIndex]?.options.map((option, index) => (
              <div
                className={`${styles.answer} ${
                  answers[questionIndex].correctAnswer !==
                    answers[questionIndex].userAnswer &&
                  answers[questionIndex].userAnswer === option &&
                  answers[questionIndex].userAnswer !== ""
                    ? styles.wrong_option
                    : ""
                }
              ${
                answers[questionIndex].correctAnswer ===
                  answers[questionIndex].userAnswer &&
                answers[questionIndex].correctAnswer === option &&
                answers[questionIndex].userAnswer !== ""
                  ? styles.correct_option
                  : ""
              }
              ${
                answers[questionIndex].correctAnswer === option &&
                answers[questionIndex].userAnswer !== ""
                  ? styles.correct_option
                  : ""
              }
              `}
                key={index}
              >
                <input
                  type="radio"
                  id={index}
                  name={answers[questionIndex].id}
                  value={option}
                  onChange={(e) => handleSelectAnswer(e.target.value)}
                  checked={answers[questionIndex].userAnswer === option}
                  disabled={true}
                />
                <label htmlFor={index}>{option}</label>
              </div>
            ))}
          </form>
        </div>
        {showExplain && (
          <div className={styles.explain_container}>
            <p>Explanation</p>
<p className="text-left">            {answers[questionIndex].explanation}
</p>
            {question?.explanationImage !== null && question?.explanationImage !== "" && (
            <img src={question?.explanationImage} alt="img" />
          )}

          </div>
        )}
      </div>
    );
  }

  if (type === "answer" || type === "quizAnswred") {
    return (
      <div className={`${styles.question_container}`}>
        {type === "quizAnswred" && (
          <div className={`${styles.btns}`}>
            <div className={styles.btnContainer}>
              <button
                className={`${styles.explain_btn}`}
                onClick={() => setShowExplain((e) => !e)}
              >
                <span>
                  <MdLightbulbOutline />
                </span>{" "}
                <p>Explanation</p>
              </button>
              <button
                className={`${styles.calcBtn}`}
                onClick={() =>
                  question.calc
                    ? toast.success("Can Use Calculator In This Question")
                    : toast.error("Can't Use Calculator In This Question")
                }
              >
                <img src={question.calc ? calc : noCalc} alt="icon" />
              </button>
            </div>
            <button
              className={`${styles.repeat_btn}`}
              onClick={handleResetQuestion}
              disabled={isLoadingResetQuestion}
            >
              {isLoadingResetQuestion ? (
                <ClipLoader color="white" size={30} />
              ) : (
                <FaRepeat />
              )}
            </button>
          </div>
        )}
        <div className={`${styles.question}`}>
          {questionIndex + 1}- {question?.question}
        </div>
        <div className={styles.secodery_container}>
        {question?.questionImage !== null && question?.questionImage !== "" && (
            <img src={question?.questionImage} alt="img" />
          )}
          <form className={`${styles.answerForm}`}>
            {question?.options.map((option, index) => (
              <div
                className={`${styles.answer} ${
                  question.correctAnswer !== question.userAnswer &&
                  question.userAnswer === option &&
                  question.userAnswer !== ""
                    ? styles.wrong_option
                    : ""
                }
              ${
                question.correctAnswer === question.userAnswer &&
                question.correctAnswer === option &&
                question.userAnswer !== ""
                  ? styles.correct_option
                  : ""
              }
              ${
                question.correctAnswer === option && question.userAnswer !== ""
                  ? styles.correct_option
                  : ""
              }
              `}
                key={index}
              >
                <input
                  type="radio"
                  id={index}
                  name={question.id}
                  value={option}
                  onChange={(e) => handleSelectAnswer(e.target.value)}
                  checked={question.userAnswer === option}
                  disabled={true}
                />
                <label htmlFor={index}>{option}</label>
              </div>
            ))}
          </form>
        </div>
        {showExplain && (
          <div className={styles.explain_container}>
            <p>Explanation</p>
<p className="text-left">            {question.explaination}
</p>
            {question?.explanationImage !== null && question?.explanationImage !== "" && (
            <img src={question?.explanationImage} alt="img" />
          )}
            
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`${styles.question_container}`}>
      {(type === "quiz" ||
        type === "freeQuiz" ||
        type === "freeQuizAnswered") && (
        <div className={`${styles.btns}`}>
          <div className={styles.btnContainer}>
            <button
              className={`${styles.explain_btn}`}
              disabled={false}
              onClick={() => toast.error("Please Solve Question First !")}
            >
              <span>
                <MdLightbulbOutline />
              </span>{" "}
              <p>Explanation</p>
            </button>
            <button
              className={`${styles.calcBtn}`}
              onClick={() =>
                question.calc
                  ? toast.success("Can Use Calculator In This Question")
                  : toast.error("Can't Use Calculator In This Question")
              }
            >
              <img src={question.calc ? calc : noCalc} alt="icon" />
            </button>
          </div>
          <button
            className={`${styles.repeat_btn}`}
            disabled={false}
            onClick={() => toast.error("Please Solve Question First !")}
          >
            <FaRepeat />
          </button>
        </div>
      )}
      <div className={`${styles.question}`}>
        {questionIndex + 1}- {question?.question}
      </div>
      <div className={styles.secodery_container}>
      {question?.questionImage !== null && question?.questionImage !== "" && (
            <img src={question?.questionImage} alt="img" />
          )}
        <form className={`${styles.answerForm}`}>
          {question?.options.map((option, index) => (
            <div className={`${styles.answer}`} key={index}>
              <input
                type="radio"
                id={index}
                name={question.id}
                value={option}
                onChange={(e) => handleSelectAnswer(e.target.value)}
                checked={answer === option}
              />
              <label htmlFor={index}>{option}</label>
            </div>
          ))}
        </form>
      </div>
      {(questionIndex === answers.length - 1 ||
        type === "quiz" ||
        type === "freeQuiz") && (
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            onClick={handleSubmitAnswer}
            disabled={isLoading}
            className={`${styles.submit_answer_btn}`}
          >
            {isLoading ? (
              <BeatLoader color="white" size={8} />
            ) : (
              "Submit Answer"
            )}
          </button>
        </div>
      )}
    </div>
  );
}
export default Question;
